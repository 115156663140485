<template>
  <div class="pdf-container" ref="pdfContent">
    <!-- Header -->
    <div class="pdf-header">
      <h1>{{ t('analysis.title') }}</h1>
      <p class="date">{{ formatDate(new Date()) }}</p>
    </div>

    <!-- Floorplan Image -->
    <div class="pdf-section">
      <h2>{{ t('analysis.preview.title') }}</h2>
      <img :src="imageUrl" alt="Floorplan" class="floorplan-image"/>
    </div>

    <!-- Overall Analysis -->
    <div class="pdf-section">
      <h2>{{ t('analysis.results.title') }}</h2>
      <div v-for="category in analysisCategories" :key="category.name" class="category-section">
        <div class="category-header">
          <h3>{{ t(`analysis.results.categories.${category.name}`) }}</h3>
          <div class="rating">
            {{ calculateCategoryScore(category) }}/5
          </div>
        </div>
        <p class="description">{{ category.description }}</p>
        <div v-for="(aspect, key) in category.aspects" :key="key" class="aspect-item">
          <div class="aspect-header">
            <span>{{ t(`analysis.results.aspects.${key}`) }}</span>
            <span class="rating-label" :class="aspect.rating.toLowerCase()">
              {{ t(`analysis.results.ratings.${aspect.rating}`) }}
            </span>
          </div>
          <p>{{ aspect.description }}</p>
          <p v-if="aspect.criteria" class="criteria-text">
            {{ aspect.criteria }}
          </p>
        </div>
      </div>
    </div>

    <!-- Cost Efficiency -->
    <div class="pdf-section">
      <h2>{{ t('analysis.costEfficiency.title') }}</h2>
      <div v-if="costAnalysis">
        <div class="analysis-summary">
          <p>{{ costAnalysis.summary }}</p>
        </div>
        <div class="efficiency-grid">
          <div class="efficiency-item">
            <h4>{{ t('analysis.costEfficiency.sections.spaceEfficiency.title') }}</h4>
            <div class="rating">{{ costAnalysis.spaceEfficiency.score }}/5</div>
            <p>{{ costAnalysis.spaceEfficiency.description }}</p>
          </div>
          <div class="efficiency-item">
            <h4>{{ t('analysis.costEfficiency.sections.constructionCost.title') }}</h4>
            <div class="rating">{{ costAnalysis.constructionCost.score }}/5</div>
            <p>{{ costAnalysis.constructionCost.description }}</p>
          </div>
          <div class="efficiency-item">
            <h4>{{ t('analysis.costEfficiency.sections.energyEfficiency.title') }}</h4>
            <div class="rating">{{ costAnalysis.energyEfficiency.score }}/5</div>
            <p>{{ costAnalysis.energyEfficiency.description }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Room Evaluation -->
    <div class="pdf-section">
      <h2>{{ t('analysis.roomEval.title') }}</h2>
      <div v-for="room in roomAnalysis.results" :key="room.raumName" class="room-section">
        <div class="room-header">
          <h3>{{ room.raumName }}</h3>
          <div class="rating">{{ room.gesamtbewertung }}/5</div>
        </div>
        <div class="room-details">
          <!-- Size -->
          <div class="detail-item">
            <div class="detail-header">
              <h4>{{ t('analysis.roomEval.size') }}</h4>
              <v-rating
                :model-value="calculateSizeScore(room.groesse.tatsaechlicheGroesse, room.groesse.empfohleneGroesse)"
                readonly
                dense
                color="primary"
                length="5"
              ></v-rating>
            </div>
            <p>{{ room.groesse.tatsaechlicheGroesse }}m² 
              ({{ t('analysis.roomEval.recommended') }}: {{ room.groesse.empfohleneGroesse }}m²)</p>
          </div>

          <!-- Furniture -->
          <div class="detail-item">
            <div class="detail-header">
              <h4>{{ t('analysis.roomEval.furniture') }}</h4>
              <v-rating
                :model-value="room.moebel_bewertung.score"
                readonly
                dense
                color="primary"
                length="5"
              ></v-rating>
            </div>
            <p>{{ room.moebel_beschreibung }}</p>
          </div>

          <!-- Windows -->
          <div class="detail-item">
            <div class="detail-header">
              <h4>{{ t('analysis.roomEval.windows') }}</h4>
              <v-rating
                :model-value="room.fenster_bewertung.score"
                readonly
                dense
                color="primary"
                length="5"
              ></v-rating>
            </div>
            <p>{{ room.fenster_beschreibung }}</p>
          </div>

          <!-- Storage -->
          <div class="detail-item">
            <div class="detail-header">
              <h4>{{ t('analysis.roomEval.storage') }}</h4>
              <v-rating
                :model-value="room.stauraum_bewertung.score"
                readonly
                dense
                color="primary"
                length="5"
              ></v-rating>
            </div>
            <p>{{ room.stauraum_beschreibung }}</p>
          </div>

          <!-- Flexibility -->
          <div class="detail-item">
            <div class="detail-header">
              <h4>{{ t('analysis.roomEval.flexibility') }}</h4>
              <v-rating
                :model-value="room.flexibilitaet_bewertung.score"
                readonly
                dense
                color="primary"
                length="5"
              ></v-rating>
            </div>
            <p>{{ room.flexibilitaet_beschreibung }}</p>
          </div>

          <!-- Barrier Free -->
          <div class="detail-item">
            <div class="detail-header">
              <h4>{{ t('analysis.roomEval.barrierFree') }}</h4>
              <v-rating
                :model-value="room.barrierefrei_bewertung.score"
                readonly
                dense
                color="primary"
                length="5"
              ></v-rating>
            </div>
            <p>{{ room.barrierefrei_beschreibung }}</p>
            
            <!-- Barrier Free Measurements -->
            <div v-if="room.tuerbreite || room.bewegungsflaeche || room.schwellen" class="measurements-grid">
              <div v-if="room.tuerbreite" class="measurement-item">
                <span class="measurement-label">{{ t('analysis.roomEval.doorWidth') }}:</span>
                <span class="measurement-value">{{ room.tuerbreite }}cm</span>
              </div>
              <div v-if="room.bewegungsflaeche" class="measurement-item">
                <span class="measurement-label">{{ t('analysis.roomEval.movementArea') }}:</span>
                <span class="measurement-value">{{ room.bewegungsflaeche }}cm</span>
              </div>
              <div v-if="room.schwellen" class="measurement-item">
                <span class="measurement-label">{{ t('analysis.roomEval.threshold') }}:</span>
                <span class="measurement-value">{{ room.schwellen }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Improvement Suggestions -->
    <div class="pdf-section">
      <h2>{{ t('analysis.improvements.title') }}</h2>
      <div v-for="(suggestion, index) in suggestions" :key="index" class="suggestion-item">
        <div class="suggestion-header">
          <h4>{{ suggestion.title }}</h4>
          <span class="priority-label" :class="suggestion.priority">
            {{ t(`analysis.improvements.priority.${suggestion.priority}`) }}
          </span>
        </div>
        <p>{{ suggestion.description }}</p>
        <div class="category-label">
          {{ t(`analysis.improvements.categories.${suggestion.category}`) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const props = defineProps({
  imageUrl: String,
  analysisCategories: Array,
  costAnalysis: Object,
  roomAnalysis: Object,
  suggestions: Array
})

// Define formatDate function directly in the component
const formatDate = (date) => {
  return new Intl.DateTimeFormat('de-DE', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  }).format(date)
}

onMounted(() => {
  console.log('PDF Layout Props:', {
    imageUrl: props.imageUrl,
    categoriesCount: props.analysisCategories?.length,
    hasCostAnalysis: !!props.costAnalysis,
    roomCount: props.roomAnalysis?.results?.length,
    suggestionsCount: props.suggestions?.length
  })
})

const pdfContent = ref(null)

const calculateCategoryScore = (category) => {
  if (!category.aspects || Object.keys(category.aspects).length === 0) return 0
  
  const ratings = Object.values(category.aspects).map(aspect => {
    switch(aspect.rating.toLowerCase()) {
      case 'positive': return 5
      case 'neutral': return 3
      case 'negative': return 1
      default: return 0
    }
  })
  
  const average = ratings.reduce((sum, rating) => sum + rating, 0) / ratings.length
  return Math.round(average * 2) / 2
}

const calculateSizeScore = (actual, recommended) => {
  if (actual === -1 || recommended === 0) return 0;
  const ratio = actual / recommended;
  if (ratio >= 0.9 && ratio <= 1.2) return 5;
  if (ratio >= 0.8 && ratio < 0.9 || ratio > 1.2 && ratio <= 1.4) return 4;
  if (ratio >= 0.7 && ratio < 0.8 || ratio > 1.4 && ratio <= 1.6) return 3;
  if (ratio >= 0.5 && ratio < 0.7 || ratio > 1.6 && ratio <= 1.8) return 2;
  return 1;
}
</script>

<style scoped>
.pdf-container {
  padding: 40px;
  max-width: 210mm; /* A4 width */
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.pdf-header {
  text-align: center;
  margin-bottom: 30px;
  page-break-after: avoid;
}

.pdf-section {
  margin-bottom: 30px;
  page-break-inside: avoid;
  page-break-after: avoid;
}

.pdf-section h2 {
  page-break-after: avoid;
}

.floorplan-image {
  max-width: 100%;
  height: auto;
  margin: 20px 0;
}

.category-section {
  margin-bottom: 20px;
  page-break-inside: avoid;
}

.category-header {
  page-break-after: avoid;
  page-break-inside: avoid;
}

.category-header h3 {
  page-break-after: avoid;
}

.category-header, .room-header, .suggestion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  page-break-inside: avoid;
}

.rating {
  font-weight: bold;
  color: #1976D2;
}

.aspect-item, .efficiency-item, .room-details, .suggestion-item {
  margin: 5px 0;
  padding: 3px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.rating-label {
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 0.9em;
}

.rating-label.positive { background-color: #4CAF50; color: white; }
.rating-label.neutral { background-color: #FFC107; }
.rating-label.negative { background-color: #F44336; color: white; }

.priority-label {
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 0.9em;
}

.priority-label.high { background-color: #F44336; color: white; }
.priority-label.medium { background-color: #FFC107; }
.priority-label.low { background-color: #4CAF50; color: white; }

.category-label {
  font-size: 0.9em;
  color: #666;
  margin-top: 5px;
}

.efficiency-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

h1 { font-size: 24px; margin-bottom: 5px; }
h2 { font-size: 20px; margin-bottom: 9px; }
h3 { font-size: 18px; margin-bottom: 5px; }
h4 { font-size: 16px; margin-bottom: 5px; }
p { font-size: 14px; line-height: 1.5; }

.criteria-text {
  font-style: italic;
  color: #666;
  margin-top: 8px;
  font-size: 0.9em;
  padding-left: 16px;
  border-left: 3px solid #e0e0e0;
}

.detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.measurements-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 16px;
  margin-top: 12px;
  padding: 8px;
  background-color: #f8f8f8;
  border-radius: 4px;
}

.measurement-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  font-size: 0.9em;
}

.measurement-label {
  font-weight: 500;
  color: #666;
}

.measurement-value {
  font-weight: 600;
  color: #1976D2;
}

.measurement-item .v-icon {
  display: none;
}

.detail-item {
  margin-bottom: 24px;
  padding: 8px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.detail-item:last-child {
  margin-bottom: 0;
}

:deep(.v-rating .v-icon) {
  padding: 0;
  font-size: 16px;
}

/* Keep room sections together where possible */
.room-section {
  page-break-inside: avoid;
  break-inside: avoid;
}

.room-header {
  page-break-after: avoid;
}

/* Keep detail items together */
.detail-item {
  page-break-inside: avoid;
  break-inside: avoid;
}

/* Keep suggestion items together */
.suggestion-item {
  page-break-inside: avoid;
  break-inside: avoid;
}

/* Add space before new sections to make breaks more natural */
.pdf-section:not(:first-child) {
  margin-top: 40px;
}

/* Ensure measurements grid stays together */
.measurements-grid {
  page-break-inside: avoid;
  break-inside: avoid;
}

/* Keep efficiency items together */
.efficiency-item {
  page-break-inside: avoid;
  break-inside: avoid;
}

/* Add specific break points for long sections */
.room-details {
  break-inside: auto; /* Allow breaks between detail items if needed */
}

.efficiency-grid {
  break-inside: auto; /* Allow breaks between efficiency items if needed */
}

/* Prevent orphaned headers */
h1, h2, h3, h4 {
  page-break-after: avoid;
  break-after: avoid;
}

/* Prevent orphaned list items */
.aspect-item {
  page-break-inside: avoid;
  break-inside: avoid;
}

/* Add minimum height for sections to prevent tiny fragments */
.pdf-section {
  min-height: 100px; /* Adjust as needed */
}

/* Ensure last items don't get orphaned */
.pdf-section:last-child {
  page-break-after: auto;
}
</style> 