<template>
  <div class="analysis">
    <v-container>
      <v-row class="mb-4">
        <v-col cols="12">
          <div class="d-flex align-center justify-space-between flex-wrap gap-4">
            <div>
              <h1 class="text-h6 mb-2">{{ $t('analysis.title') }}</h1>
            </div>
            <div class="d-flex flex-wrap controls-wrapper">
              <v-btn
                color="primary"
                prepend-icon="mdi-upload"
                @click="showUploadArea = !showUploadArea"
                height="40"
                class="control-item"
                :disabled="isUploading"
              >
                {{ t('analysis.uploadNew') }}
              </v-btn>
              <v-btn
                v-if="authStore.isAuthenticated && savedAnalyses.length > 0"
                color="primary"
                prepend-icon="mdi-history"
                @click="showSavedAnalysesDialog = true"
                height="40"
                class="control-item"
                :disabled="isUploading"
              >
                {{ t('analysis.savedAnalyses.load') }}
              </v-btn>
              <div v-tooltip="tooltipText" class="control-item">
                <v-btn
                  v-if="analysis"
                  color="primary"
                  prepend-icon="mdi-file-pdf-box"
                  :disabled="!canExportPdf || !hasPremiumAccess"
                  :loading="!canExportPdf"
                  @click="handleExportPdf"
                  height="40"
                  class="w-100"
                >
                  {{ t('analysis.exportPdf') }}
                </v-btn>
              </div>
              <v-btn
                color="primary"
                prepend-icon="mdi-credit-card-plus"
                @click="handleBuyCredits"
                height="40"
                class="control-item"
                :disabled="isUploading"
              >
                {{ $t('analysis.buyCredits') }}
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-slide-y-transition>
        <div v-if="showUploadArea">
          <v-row>
            <v-col cols="12">
              <UploadArea 
                @upload-success="handleUploadSuccess" 
                @upload-state-change="handleUploadStateChange"
              />
            </v-col>
          </v-row>
        </div>
      </v-slide-y-transition>

      <template v-if="analysis">
        <v-row class="floorplan-row">
          <FloorplanPreview :imageUrl="imageUrl" class="mb-4" />
          <AnalysisResults :results="results" />
        </v-row>

        <v-row class="mt-6">
          <CostEfficiencyAnalysis :imageUrl="imageUrl" />
        </v-row>

        <v-row class="mt-6">
          <RoomEvaluation :imageUrl="imageUrl" />
        </v-row>

        <v-row class="mt-6">
          <ImprovementSuggestions :suggestions="suggestions" />
        </v-row>

        <v-row class="mt-6">
          <PremiumFeatures />
        </v-row>
      </template>
    </v-container>

    <ContactButton />

    <PayPalButton
      v-model="showPayPal"
      @payment-success="handlePaymentSuccess"
      @payment-error="handlePaymentError"
    />

    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="4000"
      location="top"
      class="text-center"
    >
      {{ snackbar.text }}
    </v-snackbar>

    <v-dialog
      v-model="showSavedAnalysesDialog"
      max-width="600px"
    >
      <v-card>
        <v-card-title class="text-h5 pa-4">
          {{ t('analysis.savedAnalyses.dialogTitle') }}
        </v-card-title>
        <v-card-text class="pa-4">
          <v-list lines="two">
            <v-list-item
              v-for="analysis in savedAnalyses"
              :key="analysis.id"
              @click="handleAnalysisSelect(analysis.id)"
              :title="analysis.date"
              :subtitle="analysis.time"
              prepend-icon="mdi-file-document-outline"
            >
              <template v-slot:append>
                <v-btn
                  icon="mdi-chevron-right"
                  variant="text"
                  size="small"
                ></v-btn>
              </template>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            variant="text"
            @click="showSavedAnalysesDialog = false"
          >
            {{ t('common.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, createApp, h, watch } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import i18n from '@/plugins/i18n'
import { trackPdfExport } from '@/utils/analytics'
import FloorplanPreview from '@/components/FloorplanPreview.vue'
import AnalysisResults from '@/components/AnalysisResults.vue'
import ImprovementSuggestions from '@/components/ImprovementSuggestions.vue'
import PremiumFeatures from '@/components/PremiumFeatures.vue'
import CostEfficiencyAnalysis from '@/components/CostEfficiencyAnalysis.vue'
import RoomEvaluation from '@/components/RoomEvaluation.vue'
import html2pdf from 'html2pdf.js'
import AnalysisPdfLayout from '@/components/analysis/AnalysisPdfLayout.vue'
import { useAuthStore } from '@/stores/auth'
import ContactButton from '@/components/common/ContactButton.vue'
import axios from 'axios'
import { getIdToken } from 'firebase/auth'
import UploadArea from '@/components/upload/UploadArea.vue'
import PayPalButton from '@/components/common/PayPalButton.vue'
import { useRouter } from 'vue-router'

const store = useStore()
const { t } = useI18n()
const authStore = useAuthStore()
const router = useRouter()

const snackbar = ref({
  show: false,
  text: '',
  color: 'success'
})

const showNotification = (text, color = 'success') => {
  snackbar.value = {
    show: true,
    text,
    color
  }
}

// Computed properties for data from Vuex
const analysis = computed(() => store.state.analysis.analysis)
const results = computed(() => {
  // Get the categories from the analysis state
  const categories = [
    'raumaufteilung',
    'raumfluss',
    'lichteinfall',
    'funktionalitaet',
    'stauraum',
    'privatsphaere',
    'zugaenglichkeit',
    'flexibilitaet',
    'aussenbezug'
  ]
  
  // Transform the data into the format expected by AnalysisPdfLayout
  return categories
    .filter(cat => analysis.value?.[cat])
    .map(cat => {
      const category = analysis.value[cat]
      const aspects = {}
      
      Object.entries(category).forEach(([key, value]) => {
        if (key !== 'description' && typeof value === 'object') {
          aspects[key] = {
            ...value,
            criteria: value.description?.split('\n\n')[1] || ''
          }
        }
      })
      
      return {
        name: cat,
        description: category.description,
        aspects
      }
    })
})
const suggestions = computed(() => analysis.value?.suggestions || [])
const imageUrl = computed(() => store.state.analysis.imageUrl)
const costAnalysis = computed(() => store.state.analysisCost)
const roomAnalysis = computed(() => store.state.analysisRooms)

// Add a computed property to check if all data is loaded
const canExportPdf = computed(() => {
  return !!(
    analysis.value && // Main analysis data
    store.state.analysisCost && // Cost analysis
    store.state.analysisRooms && // Room analysis
    imageUrl.value // Image URL
  )
})

// Check if user has premium access
const hasPremiumAccess = computed(() => authStore.credits > 0 || store.state.userHadPremiumWhenUploaded) 

// Tooltip text for the export button
const tooltipText = computed(() => {
  if (!authStore.isAuthenticated) {
    return t('analysis.export.loginRequired')
  }
  if (!hasPremiumAccess.value) {
    return t('analysis.export.creditsRequired')
  }
  if (!canExportPdf.value) {
    return t('analysis.export.loading')
  }
  return ''
})

// Add these refs
const savedAnalyses = ref([])

// Add this function to fetch saved analyses
const fetchSavedAnalyses = async () => {
  try {
    const token = await getIdToken(authStore.user)
    const response = await axios.get('/api/user-analyses', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    
    if (response.data.success) {
      savedAnalyses.value = response.data.data.map(analysis => {
        const date = new Date(analysis.timestamp)
        return {
          id: analysis.id,
          label: date.toLocaleString(),
          date: date.toLocaleDateString(),
          time: date.toLocaleTimeString()
        }
      })
    }
  } catch (error) {
    console.error('Failed to fetch saved analyses:', error)
    showNotification(t('analysis.savedAnalyses.fetchError'), 'error')
  }
}

// Add this with other refs
const showSavedAnalysesDialog = ref(false)

// Modify handleAnalysisSelect to close the dialog
const handleAnalysisSelect = async (analysisId) => {
  if (!analysisId) return;

  try {
    const token = await getIdToken(authStore.user);
    const response = await axios.get(`/api/analysis/${analysisId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    if (response.data.success) {
      const data = response.data.data;
      
      // Update Vuex store with the loaded data
      store.commit('setAnalysis', {
        analysis: data.analysis,
        imageUrl: data.imageUrl
      });
      store.commit('setAnalysisCost', data.costAnalysis);
      store.commit('setAnalysisRooms', data.roomAnalysis);
      // Update lastAnalyzedImage when loading saved analysis
      store.commit('setLastAnalyzedImage', data.imageUrl);
      // allow vieweing premium content since saving was only possible with sufficient credits
      store.commit('setUserHadPremiumWhenUploaded', true);
      
      // Set saved state
      analysisSaved.value = true;
      // hide upload area
      showUploadArea.value = false;
      
      // Close the dialog after successful load
      showSavedAnalysesDialog.value = false;
      
      // Show success notification
      showNotification(t('analysis.savedAnalyses.loadSuccess'), 'success');      
    }
  } catch (error) {
    console.error('Failed to load analysis:', error);
    showNotification(t('analysis.savedAnalyses.loadError'), 'error');
  }
}

// Add this watch effect after the other watch effects
watch(
  () => authStore.isAuthenticated,
  async (isAuthenticated, wasAuthenticated) => {
    // Only fetch when changing from unauthenticated to authenticated
    if (isAuthenticated && !wasAuthenticated) {
      await fetchSavedAnalyses()
    }
  }
)

// Modify the onMounted hook
onMounted(() => {
  // Scroll to top
  window.scrollTo(0, 0)
  
  // if we got here from main page (without uploading an image), show the upload area as default
  if (!analysis.value) {
    showUploadArea.value = true
  }
  
  // If already authenticated on mount, fetch saved analyses
  if (authStore.isAuthenticated) {
    fetchSavedAnalyses()
  }
})

const handleExportPdf = async () => {
  if (!hasPremiumAccess.value) {
    return
  }
  
  // Track the export attempt
  trackPdfExport()
  
  const pdfContainer = document.createElement('div')
  
  // Create app with i18n plugin
  const vNode = h(AnalysisPdfLayout, {
    imageUrl: imageUrl.value,
    analysisCategories: results.value,
    costAnalysis: costAnalysis.value,
    roomAnalysis: roomAnalysis.value,
    suggestions: suggestions.value
  })
  
  // Create wrapper component to provide i18n
  const WrapperComponent = {
    setup() {
      return () => vNode
    }
  }

  const app = createApp(WrapperComponent)
  app.use(i18n) // Add i18n to the app instance
  
  // Mount the app to the container
  app.mount(pdfContainer)
  document.body.appendChild(pdfContainer)

  try {
    const opt = {
      margin: 10,
      filename: `floorplan-analysis-${new Date().toISOString().split('T')[0]}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    }

    await html2pdf().set(opt).from(pdfContainer).save()
  } finally {
    // Clean up
    app.unmount()
    document.body.removeChild(pdfContainer)
  }
}

const analysisSaved = ref(false)

const canSaveAnalysis = computed(() => {
  return !!(
    analysis.value && // Main analysis data
    costAnalysis.value && // Cost analysis
    roomAnalysis.value && // Room analysis
    imageUrl.value && // Image URL
    !analysisSaved.value // Not already saved
  )
})

// Add a watch effect to trigger auto-save
watch(
  canSaveAnalysis,
  async (canSave) => {
    if (canSave && authStore.isAuthenticated && hasPremiumAccess.value) {
      try {
        const token = await getIdToken(authStore.user)
        
        await axios.post('/api/store-analysis', {
          analysis: analysis.value,
          costAnalysis: costAnalysis.value,
          roomAnalysis: roomAnalysis.value,
          imageUrl: imageUrl.value
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
        
        analysisSaved.value = true
        showNotification(t('analysis.saveAnalysis.autoSaveSuccess'), 'success')
        await fetchSavedAnalyses() // Refresh the list
      } catch (error) {
        console.error('Failed to auto-save analysis:', error)
        showNotification(t('analysis.saveAnalysis.error'), 'error')
      }
    }
  }
)

// Add new refs
const showUploadArea = ref(false)

// Add this ref near the top of the script section with other refs
const isUploading = ref(false)

// Add handler for upload success
const handleUploadSuccess = (data) => {
  // Update store with new analysis data
  store.commit('setAnalysis', {
    analysis: data.analysis,
    imageUrl: data.imageUrl
  })
  
  // Reset lastAnalyzedImage for new upload
  store.commit('setLastAnalyzedImage', null)
  
  // Reset upload area visibility
  showUploadArea.value = false
  
  // Reset saved state for new analysis
  analysisSaved.value = false
  isUploading.value = false;
}

// Add this method in the script section
const handleUploadStateChange = (uploading) => {
  isUploading.value = uploading;
};

// Add new refs and handlers
const showPayPal = ref(false)

const handleBuyCredits = () => {
  if (!authStore.isAuthenticated) {
    router.push({
      path: '/sign-in',
      query: { redirect: 'analysis' }
    })
    return
  }
  
  showPayPal.value = true
}

const handlePaymentSuccess = () => {
  showNotification(t('analysis.payment.success'), 'success')
  authStore.fetchCredits()
}

const handlePaymentError = (error) => {
  console.error('Payment failed:', error)
  showNotification(t('analysis.payment.error'), 'error')
}
</script>

<style scoped>
.analysis {
  min-height: 100vh;
  background-color: var(--background);
}

/* Typography overrides */
h1 {
  color: var(--text-primary);
}

p {
  color: var(--text-secondary);
}

:deep(.v-card) {
  border-radius: 16px;
  transition: all 0.3s ease;
}

:deep(.v-card:hover) {
  box-shadow: var(--shadow-md);
}

.analysis-select {
  min-width: 300px;
  max-width: 400px;
  
  :deep(.v-field) {
    padding: 0 12px;
  }
  
  @media (max-width: 660px) {
    min-width: 100%;
  }
}

/* Add these new styles */
.d-flex.gap-2 {
  align-items: center;
}

.d-flex.gap-2 > * {
  flex-shrink: 0;
}

@media (max-width: 660px) {
  .d-flex.gap-2 {
    width: 100%;
  }
  
  .d-flex.gap-2 > * {
    flex: 1;
  }
  
  .d-flex.gap-2 .v-btn {
    width: 100%;
  }
}

.controls-wrapper {
  gap: 16px;
  align-items: center;
}

.control-item {
  margin: 4px 0;
}

@media (max-width: 660px) {
  .controls-wrapper {
    width: 100%;
    gap: 12px;
  }
  
  .control-item {
    width: 100%;
  }
}

.gap-4 {
  gap: 1rem;
}

/* Add these new styles */
:deep(.v-slide-y-transition-enter-active),
:deep(.v-slide-y-transition-leave-active) {
  transition-duration: 0.3s !important;
}
</style>
