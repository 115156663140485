<template>
  <div class="language-switcher">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      location="bottom"
    >
      <template v-slot:activator="{ props }">
        <v-btn
          variant="text"
          v-bind="props"
          class="lang-button"
          :class="{ 'selected': menu, 'mobile': isMobile }"
          :block="isMobile"
        >
          <div class="d-flex align-center" :class="{ 'justify-space-between': isMobile, 'w-100': isMobile }">
            <div class="d-flex align-center">
              <img
                :src="currentFlag"
                :alt="currentLanguage.name"
                class="flag-icon me-2"
              />
              <span class="text-body-2">{{ currentLanguage.name }}</span>
            </div>
            <v-icon
              size="small"
              :class="{ 'rotate-180': menu }"
              class="ms-1 transition-transform"
            >
              mdi-chevron-down
            </v-icon>
          </div>
        </v-btn>
      </template>

      <v-card 
        min-width="180" 
        elevation="3" 
        rounded="lg" 
        class="pa-2"
        :class="{ 'mobile-menu': isMobile }"
      >
        <v-list class="pa-0">
          <v-list-item
            v-for="lang in availableLanguages"
            :key="lang.code"
            :active="lang.code === currentLocale"
            color="primary"
            rounded="lg"
            class="mb-1"
            @click="changeLanguage(lang.code)"
          >
            <template v-slot:prepend>
              <img
                :src="lang.flag"
                :alt="lang.name"
                class="flag-icon me-2"
              />
            </template>
            <v-list-item-title>{{ lang.name }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDisplay } from 'vuetify'
import { saveLanguagePreference } from '@/utils/languageUtils'

const { locale } = useI18n()
const { mobile } = useDisplay()
const menu = ref(false)

const emit = defineEmits(['language-changed'])

const isMobile = computed(() => mobile.value)

const availableLanguages = [
  {
    code: 'en',
    name: 'English',
    flag: '/flags/gb.svg'
  },
  {
    code: 'de',
    name: 'Deutsch',
    flag: '/flags/de.svg'
  }
]

const currentLocale = computed(() => locale.value)

const currentLanguage = computed(() => {
  return availableLanguages.find(lang => lang.code === currentLocale.value)
})

const currentFlag = computed(() => currentLanguage.value.flag)

const changeLanguage = (langCode) => {
  locale.value = langCode
  saveLanguagePreference(langCode)
  menu.value = false
  emit('language-changed', langCode)
}
</script>

<style scoped>
.language-switcher {
  display: inline-block;
  width: 100%;
}

.lang-button {
  min-width: 120px;
  height: 36px !important;
  font-weight: 600;
}

.lang-button.mobile {
  width: 100%;
  justify-content: space-between;
}

.lang-button.selected {
  background: rgba(var(--v-theme-primary), 0.04);
}

.flag-icon {
  width: 20px;
  height: 15px;
  object-fit: cover;
  border-radius: 2px;
}

.rotate-180 {
  transform: rotate(180deg);
}

.transition-transform {
  transition: transform 0.2s ease-in-out;
}

.w-100 {
  width: 100%;
}

.mobile-menu {
  width: calc(100vw - 32px);
  max-width: 300px;
}

.text-body-2 {
  font-weight: 600;
}
</style>
