import { createStore } from 'vuex';

export default createStore({
  state: {
    analysis: {
      analysis: null,
      imageUrl: null
    },
    analysisRooms: null,
    analysisCost: null,
    modelQuality: 'fast',
    userHadPremiumWhenUploaded: false,
    lastAnalyzedImage: null,
  },
  mutations: {
    setAnalysis(state, { analysis, imageUrl }) {
      state.analysis = {
        analysis,
        imageUrl
      }
    },
    setAnalysisRooms(state, rooms) {
      state.analysisRooms = rooms
    },
    setAnalysisCost(state, cost) {
      state.analysisCost = cost
    },
    setModelQuality(state, quality) {
      state.modelQuality = quality
    },
    resetAnalysisData(state) {
      state.analysis = {
        analysis: null,
        imageUrl: null
      };
      state.analysisRooms = null;
      state.analysisCost = null;
      state.lastAnalyzedImage = null;
    },
    resetState(state) {
      state.analysis = {
        analysis: null,
        imageUrl: null
      };
      state.analysisRooms = null;
      state.analysisCost = null;
      state.modelQuality = 'fast';
      state.userHadPremiumWhenUploaded = false;
    },
    setUserHadPremiumWhenUploaded(state, hadPremium) {
      state.userHadPremiumWhenUploaded = hadPremium;
    },
    setLastAnalyzedImage(state, url) {
      state.lastAnalyzedImage = url
    }
  }
})
