<template>
  <div>
    <!-- PayPal Modal -->
    <div v-show="showPayPal" class="paypal-overlay">
      <div class="paypal-modal">
        <v-btn
          icon="mdi-close"
          variant="text"
          size="small"
          class="close-button"
          @click="closeModal"
          :aria-label="$t('pricing.paypal.close')"
        />
        
        <div class="text-center mb-6">
          <h3 class="text-h5 font-weight-bold mb-2">{{ $t('pricing.paypal.title') }}</h3>
          <p class="text-subtitle-1 mb-2">
            {{ $t('pricing.paypal.subtitle', { credits: pricingConfig?.credits }) }}
          </p>
          <p class="text-body-2 text-medium-emphasis">
            {{ $t('pricing.paypal.description', { 
              price: formatPrice(pricingConfig?.discountedPrice),
              currency: pricingConfig?.currency 
            }) }}
          </p>
        </div>

        <div ref="paypalButtonsContainer"></div>
      </div>
    </div>

    <!-- Notification Snackbar -->
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="4000"
      location="top"
      class="text-center"
    >
      {{ snackbar.text }}
    </v-snackbar>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch, onUnmounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useAuthStore } from '@/stores/auth'
import { PayPalService } from '@/utils/paypal'
import { getIdToken } from 'firebase/auth'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  }
})

const emit = defineEmits(['update:modelValue', 'payment-success', 'payment-error'])

const { t, locale } = useI18n()
const authStore = useAuthStore()
const paypalButtonsContainer = ref(null)
const pricingConfig = ref(null)
const paypalService = new PayPalService(authStore)

const showPayPal = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})

const snackbar = ref({
  show: false,
  text: '',
  color: 'success'
})

onMounted(async () => {
  try {
    await paypalService.loadPayPalScript()
    await initializePayPal()
  } catch (error) {
    console.error('Failed to load PayPal:', error)
    showNotification(t('pricing.payment.error'), 'error')
  }
})

const initializePayPal = async () => {
  try {
    // Clear the container first
    if (paypalButtonsContainer.value) {
      paypalButtonsContainer.value.innerHTML = '';
    }

    let headers = {};
    if (authStore.isAuthenticated) {
      const token = await getIdToken(authStore.user);
      headers.Authorization = `Bearer ${token}`;
    }

    const response = await fetch(`/api/pricing?locale=${locale.value}`, { headers });
    pricingConfig.value = await response.json();

    // Add small delay to ensure DOM is ready
    await new Promise(resolve => setTimeout(resolve, 100));

    await paypalService.initializePayPalButtons(paypalButtonsContainer.value, {
      onApproveCallback: (result) => {
        showPayPal.value = false
        showNotification(t('pricing.payment.success'), 'success')
        emit('payment-success', result)
      },
      onErrorCallback: (error) => {
        console.error('PayPal error:', error)
        showNotification(t('pricing.payment.error'), 'error')
        emit('payment-error', error)
      },
      locale: locale.value,
      pricingConfig: pricingConfig.value
    })
  } catch (error) {
    console.error('Failed to initialize PayPal:', error)
    showNotification(t('pricing.payment.error'), 'error')
  }
}

const closeModal = () => {
  showPayPal.value = false
}

const showNotification = (text, color = 'success') => {
  snackbar.value = {
    show: true,
    text,
    color
  }
}

const formatPrice = (price) => {
  if (!price) return '';
  return price.toFixed(2).replace('.', ',');
};

watch(() => showPayPal.value, async (newValue) => {
  if (newValue) {
    try {
      let headers = {};
      if (authStore.isAuthenticated) {
        const token = await getIdToken(authStore.user);
        headers.Authorization = `Bearer ${token}`;
      }

      const response = await fetch(`/api/pricing?locale=${locale.value}`, { headers });
      pricingConfig.value = await response.json();
      
      // Re-initialize PayPal buttons when dialog is opened
      await paypalService.loadPayPalScript();
      await initializePayPal();
    } catch (error) {
      console.error('Failed to fetch pricing:', error);
      showNotification(t('pricing.payment.error'), 'error');
    }
  }
});

watch(
  () => authStore.user,
  async () => {
      try {
        let headers = {};
        if (authStore.isAuthenticated) {
          const token = await getIdToken(authStore.user);
          headers.Authorization = `Bearer ${token}`;
        }

        const response = await fetch(`/api/pricing?locale=${locale.value}`, { headers });
        pricingConfig.value = await response.json();
      } catch (error) {
        console.error('Failed to fetch pricing:', error);
        showNotification(t('pricing.payment.error'), 'error');
      }
    
  }
);

// Add cleanup when component is unmounted
onUnmounted(() => {
  if (paypalService.buttons) {
    paypalService.buttons.close();
  }
});
</script>

<style scoped>
.paypal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.paypal-modal {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 90%;
  position: relative;
}

.close-button {
  position: absolute;
  top: 8px;
  right: 8px;
}
</style> 