<template>
  <v-col cols="12">
    <v-card class="room-evaluation">
      <v-card-title class="d-flex align-center">
        <v-icon icon="mdi-home-analytics" class="mr-2" />
        {{ $t('analysis.roomEval.title') }}
      </v-card-title>

      <v-card-text>
        <div v-if="loading" class="d-flex flex-column align-center ma-4">
          <v-progress-circular
            indeterminate
            color="primary"
            class="mb-4"
          ></v-progress-circular>
          <span class="text-medium-emphasis">{{ $t('analysis.roomEval.loading') }}</span>
        </div>

        <template v-else-if="analysis">
          <v-expansion-panels
            @update:modelValue="handlePanelChange"
          >
            <v-expansion-panel
              v-for="(room, index) in analysis.results"
              :key="room.raumName"
            >
              <v-expansion-panel-title>
                <div class="d-flex align-center">
                  <span class="text-h6">{{ room.raumName }}</span>
                  <v-rating
                    :model-value="room.gesamtbewertung"
                    readonly
                    density="compact"
                    color="primary"
                    length="5"
                    class="ml-4" 
                  ></v-rating>
                </div>
              </v-expansion-panel-title>

              <v-expansion-panel-text>
                <v-row class="evaluation-grid">
                  <!-- Raumgröße -->
                  <v-col cols="12" sm="6" lg="4">
                    <v-card class="evaluation-card h-100">
                      <v-card-item>
                        <div class="d-flex align-center mb-4">
                          <v-icon icon="mdi-ruler-square" class="mr-2" size="24" color="primary"/>
                          <span class="text-subtitle-1 font-weight-medium">{{ $t('analysis.roomEval.size') }}</span>
                        </div>
                        <v-list-item density="compact">
                          <template v-slot:prepend>
                            <v-icon :color="getSizeRatingColor(room.groesse)" size="28">
                              mdi-chart-box
                            </v-icon>
                          </template>
                          <v-list-item-title>
                            <div class="text-body-1 mb-2">
                              <template v-if="room.groesse.tatsaechlicheGroesse !== -1">
                                {{ room.groesse.tatsaechlicheGroesse }}m² 
                                <span class="text-medium-emphasis">({{ $t('analysis.roomEval.recommended') }}: {{ room.groesse.empfohleneGroesse }}m²)</span>
                              </template>
                              <template v-else>
                                <v-alert
                                  type="info"
                                  density="compact"
                                  variant="text"
                                  class="pa-0 text-wrap"
                                >
                                  {{ $t('analysis.roomEval.sizeNotDetermined') }}
                                  <span class="text-medium-emphasis text-wrap">
                                    ({{ $t('analysis.roomEval.recommended') }}: {{ room.groesse.empfohleneGroesse }}m²)
                                  </span>
                                </v-alert>
                              </template>
                            </div>
                            <v-rating
                              v-if="room.groesse.tatsaechlicheGroesse !== -1"
                              :model-value="calculateSizeScore(room.groesse.tatsaechlicheGroesse, room.groesse.empfohleneGroesse)"
                              readonly 
                              density="compact"
                              color="primary"
                              length="5"
                              class="mt-2 mb-3"
                            ></v-rating>
                          </v-list-item-title>
                        </v-list-item>
                      </v-card-item>
                    </v-card>
                  </v-col>

                  <!-- Möblierung -->
                  <v-col cols="12" sm="6" lg="4">
                    <v-card class="evaluation-card h-100">
                      <v-card-item>
                        <div class="d-flex align-center mb-4">
                          <v-icon icon="mdi-sofa" class="mr-2" size="24" color="primary"/>
                          <span class="text-subtitle-1 font-weight-medium">{{ $t('analysis.roomEval.furniture') }}</span>
                        </div>
                        <v-list-item density="compact">
                          <div class="d-flex align-center">
                            <v-rating
                              :model-value="room.moebel_bewertung.score"
                              readonly
                              density="compact"
                              color="primary"
                              length="5"
                            ></v-rating>
                          </div>
                          <PremiumContent 
                            :is-premium="isPremiumRoom(index)"
                            @upgrade="handlePremiumContent(room.raumName)"
                          >
                            <v-list-item-subtitle class="mt-2">
                              {{ room.moebel_beschreibung }}
                            </v-list-item-subtitle>
                          </PremiumContent>
                        </v-list-item>
                      </v-card-item>
                    </v-card>
                  </v-col>

                  <!-- Fenster und Belichtung -->
                  <v-col cols="12" sm="6" lg="4">
                    <v-card class="evaluation-card h-100">
                      <v-card-item>
                        <div class="d-flex align-center mb-4">
                          <v-icon icon="mdi-window-open" class="mr-2" size="24" color="primary"/>
                          <span class="text-subtitle-1 font-weight-medium">{{ $t('analysis.roomEval.windows') }}</span>
                        </div>
                        <v-list-item density="compact">
                          <div class="d-flex align-center">
                            <v-rating
                              :model-value="room.fenster_bewertung.score"
                              readonly
                              density="compact"
                              color="primary"
                              length="5"
                            ></v-rating>
                          </div>
                          <PremiumContent 
                            :is-premium="isPremiumRoom(index)"
                            @upgrade="handlePremiumContent(room.raumName)"
                          >
                            <v-list-item-subtitle class="mt-2">
                              {{ room.fenster_beschreibung }}
                            </v-list-item-subtitle>
                          </PremiumContent>
                        </v-list-item>
                      </v-card-item>
                    </v-card>
                  </v-col>

                  <!-- Energieeffizienz -->
                  <v-col cols="12" sm="6" lg="4">
                    <v-card class="evaluation-card h-100">
                      <v-card-item>
                        <div class="d-flex align-center mb-4">
                          <v-icon icon="mdi-lightning-bolt" class="mr-2" size="24" color="primary"/>
                          <span class="text-subtitle-1 font-weight-medium">{{ $t('analysis.roomEval.energy') }}</span>
                        </div>
                        <v-list-item density="compact">
                          <div class="d-flex align-center">
                            <v-rating
                              :model-value="room.energieeffizienz_bewertung.score"
                              readonly
                              density="compact"
                              color="primary"
                              length="5"
                            ></v-rating>
                          </div>
                          <PremiumContent 
                            :is-premium="isPremiumRoom(index)"
                            @upgrade="handlePremiumContent(room.raumName)"
                          >
                            <v-list-item-subtitle class="mt-2">
                              {{ room.energieeffizienz_beschreibung }}
                            </v-list-item-subtitle>
                          </PremiumContent>
                        </v-list-item>
                      </v-card-item>
                    </v-card>
                  </v-col>

                  <!-- Raumaspekte -->
                  <v-col cols="12" sm="6" lg="8">
                    <v-card class="evaluation-card h-100">
                      <v-card-item>
                        <div class="d-flex align-center mb-4">
                          <v-icon icon="mdi-view-grid-plus" class="mr-2" size="24" color="primary"/>
                          <span class="text-subtitle-1 font-weight-medium">{{ $t('analysis.roomEval.aspects') }}</span>
                        </div>
                        
                        <!-- Stauraum -->
                        <v-list-item class="mb-4">
                          <template v-slot:prepend>
                            <v-icon icon="mdi-storage-tank-outline" size="28" color="primary"/>
                          </template>
                          <v-list-item-title class="mb-2">{{ $t('analysis.roomEval.storage') }}</v-list-item-title>
                          <div class="d-flex align-center mb-3">
                            <v-rating
                              :model-value="room.stauraum_bewertung.score"
                              readonly
                              density="compact"
                              color="primary"
                              length="5"
                            ></v-rating>
                          </div> 
                          <PremiumContent 
                            :is-premium="isPremiumRoom(index)"
                            @upgrade="handlePremiumContent(room.raumName)"
                          >
                            <v-list-item-subtitle 
                              :class="[
                                'mt-2',
                                isPremiumRoom(index) ? 'premium-content' : ''
                              ]"
                            >
                              {{ room.stauraum_beschreibung }}
                            </v-list-item-subtitle>
                          </PremiumContent>
                        </v-list-item>

                        <!-- Flexibilität -->
                        <v-list-item>
                          <template v-slot:prepend>
                            <v-icon icon="mdi-sync" size="28" color="primary"/>
                          </template>
                          <v-list-item-title>{{ $t('analysis.roomEval.flexibility') }}</v-list-item-title>
                          <div class="d-flex align-center">
                            <v-rating
                              :model-value="room.flexibilitaet_bewertung.score"
                              readonly
                              density="compact"
                              color="primary"
                              length="5"
                            ></v-rating>
                          </div>
                          <PremiumContent 
                            :is-premium="isPremiumRoom(index)"
                            @upgrade="handlePremiumContent(room.raumName)"
                          >
                            <v-list-item-subtitle 
                              :class="[
                                'mt-2',
                                isPremiumRoom(index) ? 'premium-content' : ''
                              ]"
                            >
                              {{ room.flexibilitaet_beschreibung }}
                            </v-list-item-subtitle>
                          </PremiumContent>
                        </v-list-item>

                        <!-- Barrierefreiheit -->
                        <v-list-item>
                          <template v-slot:prepend>
                            <v-icon icon="mdi-wheelchair" size="28" color="primary"/>
                          </template>
                          <v-list-item-title>{{ $t('analysis.roomEval.barrierFree') }}</v-list-item-title>
                          <div class="d-flex align-center">
                            <v-rating
                              :model-value="room.barrierefrei_bewertung.score"
                              readonly
                              density="compact"
                              color="primary"
                              length="5"
                            ></v-rating>
                          </div>
                          <PremiumContent 
                            :is-premium="isPremiumRoom(index)"
                            @upgrade="handlePremiumContent(room.raumName)"
                          >
                            <v-list-item-subtitle 
                              :class="[
                                'mt-2',
                                isPremiumRoom(index) ? 'premium-content' : ''
                              ]"
                            >
                              {{ room.barrierefrei_beschreibung }}
                            </v-list-item-subtitle>
                          </PremiumContent>
                          
                          <v-list-item v-if="room.tuerbreite || room.bewegungsflaeche">
                            <v-list>
                              <v-list-item>
                                <v-icon icon="mdi-door" class="mr-2" />
                                {{ room.tuerbreite }}cm
                              </v-list-item>
                              <v-list-item>
                                <v-icon icon="mdi-rotate-orbit" class="mr-2" />
                                {{ room.bewegungsflaeche }}cm
                              </v-list-item>
                              <v-list-item v-if="room.schwellen">
                                <v-icon icon="mdi-stairs" class="mr-2" />
                                {{ $t('analysis.roomEval.threshold') }}: {{ room.schwellen }}
                              </v-list-item>
                            </v-list>
                          </v-list-item>
                        </v-list-item>
                      </v-card-item>
                    </v-card>
                  </v-col>
                </v-row>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>

        <v-alert
          v-else
          type="error"
          class="mt-2"
        >
          {{ $t('analysis.roomEval.error') }}
          <br />
          <v-btn
            color="primary"
            variant="text"
            class="mt-2"
            :loading="loading"
            @click="loadAnalysis"
          >
            <v-icon icon="mdi-refresh" class="mr-2" />
            {{ $t('common.retry') }}
          </v-btn>
        </v-alert>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue'
import axios from 'axios'
import { useI18n } from 'vue-i18n'
import { useAuthStore } from '@/stores/auth'
import PremiumContent from '@/components/common/PremiumContent.vue'
import { 
  trackRoomExpanded,
  trackRoomPremiumClick
} from '@/utils/analytics'
import { getIdToken } from 'firebase/auth'
import { useStore } from 'vuex'

const props = defineProps({
  imageUrl: {
    type: String,
    required: true
  }
})

const loading = ref(false)
// Convert analysis from ref to computed
const analysis = computed(() => store.state.analysisRooms || null)
const { locale } = useI18n()
const authStore = useAuthStore()
const hasPremiumAccess = computed(() => store.state.userHadPremiumWhenUploaded)
const store = useStore()

// Update isPremium logic to use credits
const isPremiumRoom = (index) => {
  if (hasPremiumAccess.value) return false
  return index > 0
}

const calculateSizeScore = (actual, recommended) => {
  if (actual === -1 || recommended === 0) return 0;
  const ratio = actual / recommended;
  if (ratio >= 0.9 && ratio <= 1.2) return 5;
  if (ratio >= 0.8 && ratio < 0.9 || ratio > 1.2 && ratio <= 1.4) return 4;
  if (ratio >= 0.7 && ratio < 0.8 || ratio > 1.4 && ratio <= 1.6) return 3;
  if (ratio >= 0.5 && ratio < 0.7 || ratio > 1.6 && ratio <= 1.8) return 2;
  return 1;
};

const getSizeRatingColor = (groesse) => {
  if (groesse.tatsaechlicheGroesse === -1) return 'info'
  if (groesse.tatsaechlicheGroesse >= groesse.empfohleneGroesse) return 'success'
  if (groesse.tatsaechlicheGroesse < groesse.empfohleneGroesse) return 'warning'
  return 'error'
}

// Track panel expansion
const handlePanelChange = (index) => {
  if (index !== -1 && analysis.value?.results[index]) {
    const room = analysis.value.results[index]
    trackRoomExpanded(
      room.raumName,
      room.gesamtbewertung
    )
  }
}

// Update premium content handler
const handlePremiumContent = (roomName) => {
  trackRoomPremiumClick(roomName)
  
  if (!hasPremiumAccess.value) {
    navigateToPricing()
  }
}

// Add navigation helper
const navigateToPricing = () => {
  window.scrollTo({
    top: document.documentElement.scrollHeight,
    behavior: 'smooth'
  });
}

// Define loadAnalysis before using it in the watch
const loadAnalysis = async () => {
  // Add check for ongoing analysis
  if (loading.value) {
    return;
  }

  // If we already have analysis data in store and the imageUrl hasn't changed, use that
  if (store.state.analysisRooms && store.state.lastAnalyzedImage === props.imageUrl) {
    loading.value = false
    return
  }

  // Reset state when starting new analysis
  store.commit('setAnalysisRooms', null)
  loading.value = true
  
  try {
    let headers = {}
    if (authStore.isAuthenticated) {
      const token = await getIdToken(authStore.user)
      headers.Authorization = `Bearer ${token}`
    }

    const response = await axios.post('/api/analyze-barrier-free', {
      imageUrl: props.imageUrl,
      language: locale.value,
      model: store.state.modelQuality
    }, { headers })
    
    if (response.data?.data) {
      store.commit('setAnalysisRooms', response.data.data)
      // Store the last analyzed image URL
      store.commit('setLastAnalyzedImage', props.imageUrl)
      await authStore.fetchCredits()
    } else {
      throw new Error('Failed to load room analysis')
    }
  } catch (error) {
    console.error('Failed to load room analysis:', error)
  } finally {
    loading.value = false
  }
}

// Modify the watcher to use immediate: false and check for actual changes
watch(() => props.imageUrl, (newUrl, oldUrl) => {
  if (newUrl && newUrl !== oldUrl) {
    loadAnalysis()
  }
}, { immediate: false })

// Update onMounted to only load if needed
onMounted(() => {
  if (props.imageUrl && !store.state.analysisRooms) {
    loadAnalysis()
  }
})
</script>

<style scoped>
.room-evaluation {
  border-radius: 8px;
}

.evaluation-grid {
  gap: 16px;
  margin: 0 -8px;
}

:deep(.evaluation-card) {
  transition: transform 0.2s, box-shadow 0.2s;
  border: 1px solid rgba(var(--v-border-color), 0.1);
  height: 100%;
}

:deep(.evaluation-card:hover) {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

:deep(.v-card-item) {
  padding: 20px;
}

:deep(.v-expansion-panels) {
  border-radius: 4px;
}

:deep(.v-expansion-panel) {
  margin-bottom: 8px;
}

:deep(.v-list-item) {
  padding: 0;
}

:deep(.text-medium-emphasis) {
  opacity: 0.7;
  font-size: 0.9em;
}

:deep(.v-rating .v-icon) {
  padding: 0 2px;
}

:deep(.description-text) {
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.5;
  margin-top: 8px;
  font-size: 0.975rem;
}

:deep(.v-list-item-subtitle) {
  color: rgba(0, 0, 0, 0.87) !important;
  white-space: normal !important;
  line-height: 1.5 !important;
}

:deep(.v-list-item) {
  padding: 12px 0;
}

:deep(.mb-4) {
  margin-bottom: 16px !important;
}

:deep(.mb-3) {
  margin-bottom: 12px !important;
}

:deep(.mb-2) {
  margin-bottom: 8px !important;
}

:deep(.evaluation-card) {
  padding: 16px;
}

:deep(.v-card-item) {
  padding: 16px;
}

:deep(.text-wrap) {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

:deep(.v-alert) {
  width: 100%;
  max-width: 100%;
}

.description-container {
  position: relative;
}

.premium-content {
  user-select: none;
  filter: blur(4px);
}

.premium-message {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--primary);
  font-weight: bold;
  text-align: center;
  padding: 4px 12px;
  font-size: 0.85rem;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  pointer-events: auto;
  z-index: 1;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.premium-message-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.premium-item {
  position: relative;
}

.premium-item:hover .premium-message {
  display: flex;
}
</style>