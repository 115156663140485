import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { auth } from '@/firebase/config';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';

export const useAuthStore = defineStore('auth', () => {
  const user = ref(null);
  const loading = ref(true);
  const credits = ref(0);

  const isAuthenticated = computed(() => !!user.value);

  async function fetchCredits() {
    try {
      const token = await user.value?.getIdToken();
      const headers = token ? { Authorization: `Bearer ${token}` } : {};
      
      const response = await axios.get('/api/credits', { headers });
      credits.value = response.data.credits; 
    } catch (error) {
      console.error('Error fetching credits:', error);
      console.error('Response data:', error.response?.data);
      credits.value = 0;
    }
  }

  // Initialize auth state listener
  onAuthStateChanged(auth, async (firebaseUser) => {
    user.value = firebaseUser;
    if (firebaseUser) {
      await fetchCredits();
    } else {
      credits.value = 0;
    }
    loading.value = false;
  });

  return {
    user,
    loading,
    isAuthenticated,
    credits,
    fetchCredits,
  };
}); 