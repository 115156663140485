<template>
  <v-col cols="12">
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="4000"
      location="top"
      class="text-center"
    >
      {{ snackbar.text }}
    </v-snackbar>

    <v-card class="premium-features-card">
      <v-card-title class="d-flex align-center">
        <v-icon icon="mdi-star" color="warning" class="mr-2"></v-icon>
        {{ authStore.credits > 0 ? $t('premium.title_with_credits') : $t('premium.title') }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="8">
            <div class="text-body-1 mb-4">
              {{ authStore.credits > 0 ? $t('premium.subtitle_with_credits', { credits: authStore.credits }) : $t('premium.subtitle') }}
            </div>
            <v-list>
              <v-list-item prepend-icon="mdi-check-circle">
                <v-list-item-title>{{ $t('premium.features.analysis.title', { credits: pricingConfig?.credits }) }}</v-list-item-title>
                <v-list-item-subtitle>{{ $t('premium.features.analysis.description') }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item prepend-icon="mdi-brain">
                <v-list-item-title>{{ $t('premium.features.ai.title') }}</v-list-item-title>
                <v-list-item-subtitle>{{ $t('premium.features.ai.description') }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item prepend-icon="mdi-clipboard-text-search">
                <v-list-item-title>{{ $t('premium.features.recommendations.title') }}</v-list-item-title>
                <v-list-item-subtitle>{{ $t('premium.features.recommendations.description') }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item prepend-icon="mdi-cash-check">
                <v-list-item-title>{{ $t('premium.features.cost.title') }}</v-list-item-title>
                <v-list-item-subtitle>{{ $t('premium.features.cost.description') }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item prepend-icon="mdi-floor-plan">
                <v-list-item-title>{{ $t('premium.features.rooms.title') }}</v-list-item-title>
                <v-list-item-subtitle>{{ $t('premium.features.rooms.description') }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item prepend-icon="mdi-file-export">
                <v-list-item-title>{{ $t('premium.features.export.title') }}</v-list-item-title>
                <v-list-item-subtitle>{{ $t('premium.features.export.description') }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item prepend-icon="mdi-headset">
                <v-list-item-title>{{ $t('premium.features.support.title') }}</v-list-item-title>
                <v-list-item-subtitle>{{ $t('premium.features.support.description') }}</v-list-item-subtitle>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="12" md="4" class="d-flex align-center justify-center">
            <v-btn
              color="primary"
              size="large"
              @click="handleUpgradeClick"
              class="upgrade-btn"
            >
              <v-icon start icon="mdi-rocket-launch" class="mr-2"></v-icon>
              {{ $t('premium.upgradeButton') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <PayPalButton
      v-model="showPayPal"
      @payment-success="handlePaymentSuccess"
      @payment-error="handlePaymentError"
    />
  </v-col>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import PayPalButton from '@/components/common/PayPalButton.vue'
import { useAuthStore } from '@/stores/auth'
import { useI18n } from 'vue-i18n'
import { getIdToken } from 'firebase/auth'
import { trackEvent } from '@/utils/analytics'

const { t, locale } = useI18n()
const showPayPal = ref(false)
const authStore = useAuthStore()
const pricingConfig = ref(null)
const router = useRouter()

const fetchPricingConfig = async () => {
  try {
    let headers = {};
    if (authStore.isAuthenticated) {
      const token = await getIdToken(authStore.user);
      headers.Authorization = `Bearer ${token}`;
    }
    const response = await fetch(`/api/pricing?locale=${locale.value}`, { headers });
    pricingConfig.value = await response.json();
  } catch (error) {
    console.error('Failed to fetch pricing:', error);
    showNotification(t('pricing.payment.error'), 'error');
  }
}

onMounted(() => {
  fetchPricingConfig();
});

const snackbar = ref({
  show: false,
  text: '',
  color: 'success'
})

const handleUpgradeClick = async () => {
  if (!authStore.isAuthenticated) {
    await router.push('/');
    setTimeout(() => {
      const pricingElement = document.getElementById('pricing');
      if (pricingElement) {
        pricingElement.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
    return;
  }
  // Track premium upgrade button click
  trackEvent('premium_upgrade_click', {
    location: 'premium_features',
    user_credits: authStore.credits
  });
  showPayPal.value = true;
}

const handlePaymentSuccess = () => {
  // Track successful payment from premium features
  trackEvent('payment_success', {
    location: 'premium_features',
    previous_credits: authStore.credits
  });
  showNotification(t('pricing.payment.success'), 'success');
  authStore.fetchCredits();
}

const handlePaymentError = (error) => {
  // Track payment error from premium features
  trackEvent('payment_error', {
    location: 'premium_features',
    error_message: error?.message || 'Unknown error'
  });
  console.error('Payment failed:', error);
  showNotification(t('pricing.payment.error'), 'error');
}

const showNotification = (text, color = 'success') => {
  snackbar.value = {
    show: true,
    text,
    color
  }
}
</script>

<style scoped>
.premium-features-card {
  border: 1px solid var(--border-color);
  background: white;
  transition: all 0.3s ease;
}

.premium-features-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-md);
}

.upgrade-btn {
  min-width: 200px;
  background-color: var(--primary) !important;
  color: white !important;
}

.upgrade-btn:hover {
  background-color: var(--primary-dark) !important;
}

:deep(.v-list-item-subtitle) {
  white-space: normal !important;
  -webkit-line-clamp: unset !important;
  -webkit-box-orient: unset !important;
  color: var(--text-primary) !important;
}

:deep(.v-list-item-title) {
  color: var(--text-primary);
  font-weight: 600;
}

/* Add PayPal modal styles */
.paypal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.paypal-modal {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 90%;
  position: relative;
}

.close-button {
  position: absolute;
  top: 8px;
  right: 8px;
}
</style>
