<template>
  <div id="faq" class="faq-section py-16">
    <v-container>
      <v-row>
        <v-col cols="12" class="text-center mb-12">
          <h2 class="text-h1 text-sm-h3 text-xs-h3 font-weight-bold mb-3">{{ $t('faq.title') }}</h2>
          <p class="text-h6 text-medium-emphasis">
            {{ $t('faq.subtitle') }}
          </p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-expansion-panels variant="accordion" @update:modelValue="handlePanelChange">
            <v-expansion-panel
              v-for="(item, i) in faqItems.slice(0, Math.ceil(faqItems.length / 2))"
              :key="i"
              class="mb-2"
              rounded="lg"
            >
              <v-expansion-panel-title class="text-subtitle-1 font-weight-medium">
                {{ $t(item.questionKey) }}
              </v-expansion-panel-title>
              <v-expansion-panel-text class="text-body-1 text-medium-emphasis">
                {{ $t(item.answerKey) }}
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>

        <v-col cols="12" md="6">
          <v-expansion-panels variant="accordion" @update:modelValue="(panel) => handlePanelChange(panel + Math.ceil(faqItems.length / 2))">
            <v-expansion-panel
              v-for="(item, i) in faqItems.slice(Math.ceil(faqItems.length / 2))"
              :key="i + Math.ceil(faqItems.length / 2)"
              class="mb-2"
              rounded="lg"
            >
              <v-expansion-panel-title class="text-subtitle-1 font-weight-medium">
                {{ $t(item.questionKey) }}
              </v-expansion-panel-title>
              <v-expansion-panel-text class="text-body-1 text-medium-emphasis">
                {{ $t(item.answerKey) }}
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-row class="mt-12">
        <v-col cols="12" class="text-center">
          <p class="text-h6 mb-6">{{ $t('faq.more_questions') }}</p>
          <div class="d-flex justify-center gap-4">
            <v-btn
              color="primary"
              variant="outlined"
              class="text-none"
              prepend-icon="mdi-email"
              href="mailto:support@grundriss-ai.de"
            >
              {{ $t('faq.email_support') }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script setup>
import { trackEvent } from '@/utils/analytics';

const faqItems = [
  {
    questionKey: 'faq.items.how_works.question',
    answerKey: 'faq.items.how_works.answer'
  },
  {
    questionKey: 'faq.items.analysis_depth.question',
    answerKey: 'faq.items.analysis_depth.answer'
  },
  {
    questionKey: 'faq.items.file_formats.question',
    answerKey: 'faq.items.file_formats.answer'
  },
  {
    questionKey: 'faq.items.analysis_duration.question',
    answerKey: 'faq.items.analysis_duration.answer'
  },
  {
    questionKey: 'faq.items.benefits.question',
    answerKey: 'faq.items.benefits.answer'
  },
  {
    questionKey: 'faq.items.trial.question',
    answerKey: 'faq.items.trial.answer'
  },
  {
    questionKey: 'faq.items.saved_analyses.question',
    answerKey: 'faq.items.saved_analyses.answer'
  },
  {
    questionKey: 'faq.items.analysis_models.question',
    answerKey: 'faq.items.analysis_models.answer'
  }
];

// Simplified panel change tracking
const handlePanelChange = (expandedPanel) => {
  if (expandedPanel !== undefined && expandedPanel !== -1) {
    trackEvent('faq_panel_expanded');
  }
};
</script>

<style scoped>
.faq-section {
  padding: 4rem 0;
  background: var(--background-alt);
}

@media (max-width: 600px) {
  .faq-section {
    padding: 2rem 0;
  }
}

:deep(.v-expansion-panel) {
  background: white !important;
  border: 1px solid var(--border-color) !important;
  margin-bottom: 16px;
  transition: all 0.3s ease;
  border-radius: 12px !important;
  overflow: hidden;
}

:deep(.v-expansion-panel:hover) {
  border-color: var(--primary) !important;
  transform: translateY(-2px);
  box-shadow: var(--shadow-sm);
}

:deep(.v-expansion-panel-title) {
  padding: 20px 24px;
  color: var(--text-primary);
  font-weight: 600;
}

:deep(.v-expansion-panel-text) {
  padding: 0 24px 20px;
  color: var(--text-secondary);
  line-height: 1.6;
}

:deep(.v-btn) {
  height: 48px;
  padding: 0 24px;
}

:deep(.v-btn.v-btn--variant-outlined) {
  border-color: var(--primary);
  color: var(--primary);
}

:deep(.v-btn.v-btn--variant-outlined:hover) {
  background: var(--primary);
  color: white;
}

.text-h4 {
  color: var(--text-primary);
  font-weight: 700;
  margin-bottom: 1rem;
}

.text-h6 {
  color: var(--text-secondary);
  font-weight: 500;
  line-height: 1.6;
}

@media (max-width: 600px) {
  h2 {
    font-size: 1.75rem !important;
  }
}

@media (min-width: 601px) and (max-width: 960px) {
  h2 {
    font-size: 2.125rem !important;
  }
}
</style>
