import { getIdToken } from 'firebase/auth';
import { trackPaymentSuccess, trackPaymentFailure } from './analytics';

export class PayPalService {
  constructor(authStore) {
    this.authStore = authStore;
    this.locale = null;
    this.pricingConfig = null;
    this.buttons = null;
  }

  async fetchPricing() {
    let headers = {};
    if (this.authStore.isAuthenticated) {
      const token = await getIdToken(this.authStore.user);
      headers.Authorization = `Bearer ${token}`;
    }

    const response = await fetch(`/api/pricing?locale=${this.locale}`, { headers });
    this.pricingConfig = await response.json();
    return this.pricingConfig;
  }

  async loadPayPalScript() {
    await this.fetchPricing();

    const existingScript = document.querySelector('script[src*="paypal.com/sdk/js"]');
    if (existingScript) {
      existingScript.remove();
    }

    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.async = true;
      script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.VUE_APP_PAYPAL_CLIENT_ID}&components=buttons&currency=${this.pricingConfig.currency}`;
      script.addEventListener('load', () => resolve());
      script.addEventListener('error', (e) => reject(e));
      document.body.appendChild(script);
    });
  }

  async createOrder() {
    try {
      const token = await getIdToken(this.authStore.user);
      
      const response = await fetch("/api/orders", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        body: JSON.stringify({
          cart: [
            {
              id: "CREDITS_10",
              quantity: "1",
            },
          ],
          locale: this.locale
        }),
      });

      const orderData = await response.json();
      if (orderData.id) {
        return orderData.id;
      }

      const errorDetail = orderData?.details?.[0];
      throw new Error(errorDetail 
        ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})`
        : JSON.stringify(orderData));
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async captureOrder(orderId) {
    try {
      const token = await getIdToken(this.authStore.user);
      
      const response = await fetch(
        `/api/orders/${orderId}/capture`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
        }
      );

      const orderData = await response.json();
      
      const errorDetail = orderData?.details?.[0];

      if (errorDetail?.issue === "INSTRUMENT_DECLINED") {
        trackPaymentFailure();
        return { status: 'RESTART' };
      } else if (errorDetail) {
        trackPaymentFailure();
        throw new Error(`${errorDetail.description} (${orderData.debug_id})`);
      } else if (!orderData.purchase_units) {
        trackPaymentFailure();
        throw new Error(JSON.stringify(orderData));
      }

      const transaction = orderData?.purchase_units?.[0]?.payments?.captures?.[0] ||
                         orderData?.purchase_units?.[0]?.payments?.authorizations?.[0];

      // Track successful payment
      if (transaction) {
        trackPaymentSuccess(
          transaction.amount?.value,
          transaction.amount?.currency_code,
          transaction.id
        );
      }

      console.log(
        `Transaction ${transaction.status}: ${transaction.id}\nSee console for all available details`
      );      

      return { status: 'SUCCESS', transaction, orderData };
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async initializePayPalButtons(container, { onApproveCallback, onErrorCallback, locale }) {
    try {
      if (this.buttons) {
        this.buttons.close();
      }

      this.locale = locale;
      
      if (container) {
        container.innerHTML = '';
      }
      
      await this.loadPayPalScript();
      
      this.buttons = window.paypal.Buttons({
        style: {
          shape: "pill",
          layout: "vertical",
          color: "gold",
          label: "paypal",
        },
        createOrder: () => this.createOrder(),
        onApprove: async (data, actions) => {
          try {
            const result = await this.captureOrder(data.orderID);
            
            if (result.status === 'RESTART') {
              return actions.restart();
            }
            
            await this.authStore.fetchCredits();
            onApproveCallback(result);
          } catch (error) {
            onErrorCallback(error);
          }
        }
      });

      return this.buttons.render(container);
    } catch (error) {
      console.error('PayPal initialization error:', error);
      throw error;
    }
  }
} 