<template>
  <v-container class="privacy-policy">
    <v-row>
      <v-col cols="12" lg="8" class="mx-auto">
        <h1 class="text-h3 mb-8 font-weight-medium">{{ $t('privacy.title') }}</h1>
        
        <v-card class="pa-8 mb-8" elevation="2">
          <section class="mb-8">
            <h3 class="text-h5 mb-4 font-weight-medium">{{ $t('privacy.overview.general.title') }}</h3>
            <p class="text-body-1">{{ $t('privacy.overview.general.content') }}</p>
          </section>

          <section class="mb-8">
            <h3 class="text-h5 mb-4 font-weight-medium">{{ $t('privacy.overview.dataCollection.title') }}</h3>
            
            <div class="mb-6">
              <h4 class="text-h6 mb-3 font-weight-medium">{{ $t('privacy.overview.dataCollection.responsible.title') }}</h4>
              <p class="text-body-1">{{ $t('privacy.overview.dataCollection.responsible.content') }}</p>
            </div>

            <div class="mb-6">
              <h4 class="text-h6 mb-3 font-weight-medium">{{ $t('privacy.overview.dataCollection.how.title') }}</h4>
              <p class="text-body-1 mb-2">{{ $t('privacy.overview.dataCollection.how.intro') }}</p>
              <ul class="text-body-1 pl-4">
                <li class="mb-2">{{ $t('privacy.overview.dataCollection.how.method1') }}</li>
                <li class="mb-2">{{ $t('privacy.overview.dataCollection.how.method2') }}</li>
                <li class="mb-2">{{ $t('privacy.overview.dataCollection.how.method3') }}</li>
              </ul>
            </div>

            <div class="mb-6">
              <h4 class="text-h6 mb-3 font-weight-medium">{{ $t('privacy.overview.dataCollection.purpose.title') }}</h4>
              <p class="text-body-1 mb-2">{{ $t('privacy.overview.dataCollection.purpose.intro') }}</p>
              <ul class="text-body-1 pl-4">
                <li class="mb-2">{{ $t('privacy.overview.dataCollection.purpose.purpose1') }}</li>
                <li class="mb-2">{{ $t('privacy.overview.dataCollection.purpose.purpose2') }}</li>
                <li class="mb-2">{{ $t('privacy.overview.dataCollection.purpose.purpose3') }}</li>
                <li class="mb-2">{{ $t('privacy.overview.dataCollection.purpose.purpose4') }}</li>
              </ul>
            </div>

            <div class="mb-6">
              <h4 class="text-h6 mb-3 font-weight-medium">{{ $t('privacy.overview.dataCollection.storage.title') }}</h4>
              <p class="text-body-1">{{ $t('privacy.overview.dataCollection.storage.content') }}</p>
            </div>

            <div>
              <h4 class="text-h6 mb-3 font-weight-medium">{{ $t('privacy.overview.dataCollection.rights.title') }}</h4>
              <p class="text-body-1 mb-2">{{ $t('privacy.overview.dataCollection.rights.intro') }}</p>
              <ul class="text-body-1 pl-4">
                <li class="mb-2">{{ $t('privacy.overview.dataCollection.rights.right1') }}</li>
                <li class="mb-2">{{ $t('privacy.overview.dataCollection.rights.right2') }}</li>
                <li class="mb-2">{{ $t('privacy.overview.dataCollection.rights.right3') }}</li>
                <li class="mb-2">{{ $t('privacy.overview.dataCollection.rights.right4') }}</li>
              </ul>
            </div>
          </section>

          <section class="mb-8">
            <h3 class="text-h5 mb-4 font-weight-medium">{{ $t('privacy.overview.cookies.title') }}</h3>
            
            <div class="mb-6">
              <h4 class="text-h6 mb-3 font-weight-medium">{{ $t('privacy.overview.cookies.googleAnalytics.title') }}</h4>
              <p class="text-body-1">{{ $t('privacy.overview.cookies.googleAnalytics.content') }}</p>
              <p class="text-body-1">{{ $t('privacy.overview.cookies.googleAnalytics.purpose') }}</p>
              <p class="text-body-1">{{ $t('privacy.overview.cookies.googleAnalytics.dataIntro') }}</p>
              <ul class="text-body-1 pl-4">
                <li>{{ $t('privacy.overview.cookies.googleAnalytics.dataTypes.bildupload') }}</li>
                <li>{{ $t('privacy.overview.cookies.googleAnalytics.dataTypes.registration') }}</li>
                <li>{{ $t('privacy.overview.cookies.googleAnalytics.dataTypes.registrationAttempts') }}</li>
              </ul>
              <p class="text-body-1">{{ $t('privacy.overview.cookies.googleAnalytics.optOut') }}</p>
            </div>

            <div class="mb-6">
              <h4 class="text-h6 mb-3 font-weight-medium">{{ $t('privacy.overview.cookies.storageDuration.title') }}</h4>
              <p class="text-body-1">{{ $t('privacy.overview.cookies.storageDuration.content') }}</p>
              <ul class="text-body-1 pl-4">
                <li>{{ $t('privacy.overview.cookies.storageDuration.bildupload') }}</li>
                <li>{{ $t('privacy.overview.cookies.storageDuration.email') }}</li>
                <li>{{ $t('privacy.overview.cookies.storageDuration.analytics') }}</li>
              </ul>
            </div>

            <div class="mb-6">
              <h4 class="text-h6 mb-3 font-weight-medium">{{ $t('privacy.overview.cookies.ssl.title') }}</h4>
              <p class="text-body-1">{{ $t('privacy.overview.cookies.ssl.content') }}</p>
            </div>

            <div class="mb-6">
              <h4 class="text-h6 mb-3 font-weight-medium">{{ $t('privacy.overview.cookies.complaint.title') }}</h4>
              <p class="text-body-1">{{ $t('privacy.overview.cookies.complaint.content') }}</p>
            </div>

            <div class="mb-6">
              <h4 class="text-h6 mb-3 font-weight-medium">{{ $t('privacy.overview.cookies.dataTransfer.title') }}</h4>
              <p class="text-body-1">{{ $t('privacy.overview.cookies.dataTransfer.content') }}</p>
            </div>
          </section>

          <section class="mb-8">
            <h3 class="text-h5 mb-4 font-weight-medium">{{ $t('privacy.overview.authentication.title') }}</h3>
            
            <div class="mb-6">
              <h4 class="text-h6 mb-3 font-weight-medium">{{ $t('privacy.overview.authentication.general.title') }}</h4>
              <p class="text-body-1">{{ $t('privacy.overview.authentication.general.content') }}</p>
            </div>

            <div class="mb-6">
              <h4 class="text-h6 mb-3 font-weight-medium">{{ $t('privacy.overview.authentication.data.title') }}</h4>
              <p class="text-body-1 mb-2">{{ $t('privacy.overview.authentication.data.content') }}</p>
              <ul class="text-body-1 pl-4">
                <li class="mb-2">{{ $t('privacy.overview.authentication.data.email') }}</li>
                <li class="mb-2">{{ $t('privacy.overview.authentication.data.password') }}</li>
              </ul>
            </div>

            <div class="mb-6">
              <h4 class="text-h6 mb-3 font-weight-medium">{{ $t('privacy.overview.authentication.security.title') }}</h4>
              <p class="text-body-1 mb-2">{{ $t('privacy.overview.authentication.security.content') }}</p>
              <p class="text-body-1">{{ $t('privacy.overview.authentication.security.firebase') }}</p>
            </div>

            <div class="mb-6">
              <h4 class="text-h6 mb-3 font-weight-medium">{{ $t('privacy.overview.authentication.deletion.title') }}</h4>
              <p class="text-body-1">{{ $t('privacy.overview.authentication.deletion.content') }}</p>
            </div>
          </section>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Datenschutz'
}
</script>

<style scoped>
.privacy-policy {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

.privacy-policy ul {
  list-style-type: disc;
}

@media (max-width: 600px) {
  .privacy-policy {
    padding: 1rem;
  }
}
</style>
