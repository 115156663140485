<template>
  <v-col cols="12">
    <v-card>
      <v-card-title>{{ $t('analysis.improvements.title') }}</v-card-title>
      <v-card-text>
        <v-timeline v-if="suggestions && suggestions.length > 0" align="start">
          <v-timeline-item
            v-for="(suggestion, index) in suggestions"
            :key="index"
            :dot-color="getPriorityColor(suggestion.priority)"
            size="small"
          >
            <template v-slot:opposite>
              <div class="text-caption priority-label" 
                   :class="{ 'mobile-priority': $vuetify.display.smAndDown }">
                {{ $t(`analysis.improvements.priority.${suggestion.priority}`) }}
              </div>
            </template>
            <div class="suggestion-content">
              <div class="text-h6 mb-2" 
                   :class="{ 'text-subtitle-1': $vuetify.display.smAndDown }">
                <span v-if="isPremiumSuggestion(index)" 
                      class="premium-blur"
                      @click="handlePremiumContent(suggestion.title)">
                  {{ suggestion.title.split(' ')[0] }}
                </span>
                <span v-if="isPremiumSuggestion(index)">
                  {{ suggestion.title.split(' ').slice(1).join(' ') }}
                </span>
                <span v-else>{{ suggestion.title }}</span>
              </div>
              <PremiumContent 
                :is-premium="isPremiumSuggestion(index)"
                @upgrade="handlePremiumContent(suggestion.title)"
              >
                <div class="text-body-2">{{ suggestion.description }}</div>
              </PremiumContent>
              <div class="text-caption text-grey">
                {{ $t('analysis.improvements.category') }}: 
                {{ $t(`analysis.improvements.categories.${suggestion.category}`) }}
              </div>
            </div>
          </v-timeline-item>
        </v-timeline>
        <div v-else class="text-center pa-4">
          {{ $t('analysis.improvements.noSuggestions') }}
        </div>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import PremiumContent from '@/components/common/PremiumContent.vue'
import { trackSuggestionPremiumClick } from '@/utils/analytics'

const store = useStore()

const hasPremiumAccess = computed(() => store.state.userHadPremiumWhenUploaded)

const isPremiumSuggestion = (index) => {
  if (hasPremiumAccess.value) return false
  return index > 0
}

const priorityOrder = { high: 3, medium: 2, low: 1 }

const sortedSuggestions = computed(() => {
  const suggestionsObject = store.state.analysis?.analysis?.suggestions;
  if (!suggestionsObject || typeof suggestionsObject !== 'object') {
    return [];
  }

  const transformedSuggestions = Object.entries(suggestionsObject).map(([key, value]) => ({
    title: value.title || key,
    description: value.description,
    priority: value.priority || 'low',
    category: value.category || 'general'
  }));

  return transformedSuggestions.sort((a, b) => {
    return priorityOrder[b.priority] - priorityOrder[a.priority];
  });
});

const suggestions = sortedSuggestions

const getPriorityColor = (priority) => {
  const colors = {
    high: 'error',
    medium: 'warning',
    low: 'success'
  }
  return colors[priority] || 'info'
}

// Add navigation helper
const navigateToPricing = () => {
  window.scrollTo({
    top: document.documentElement.scrollHeight,
    behavior: 'smooth'
  });
}

// Add premium content handler
const handlePremiumContent = (suggestionTitle) => {
  trackSuggestionPremiumClick(suggestionTitle)
  
  if (!hasPremiumAccess.value) {
    navigateToPricing()
  }
}
</script>

<style scoped>
.suggestion-content {
  margin-bottom: 16px;
  /* Maximale Breite für bessere Lesbarkeit auf größeren Bildschirmen */
  max-width: 600px;
}

.text-h6 {
  margin-bottom: 12px;
  word-break: break-word; /* Verhindert Textüberlauf */
}

:deep(.premium-content) {
  margin-bottom: 8px;
}

/* Mobile Anpassungen */
.priority-label.mobile-priority {
  margin-bottom: 4px;
  font-weight: 500;
}

/* Timeline Anpassungen für Mobile */
:deep(.v-timeline) {
  @media (max-width: 600px) {
    padding-left: 0;
    padding-right: 0;
  }
}

:deep(.v-timeline-item__body) {
  @media (max-width: 600px) {
    padding: 0 16px;
  }
}

/* Bessere Textlesbarkeit auf Mobile */
.text-body-2 {
  @media (max-width: 600px) {
    font-size: 0.875rem;
    line-height: 1.5;
  }
}

.premium-blur {
  filter: blur(4px);
  user-select: none;
  cursor: pointer;
  display: inline-block;
}

.premium-blur:hover {
  filter: blur(6px);
}
</style>
