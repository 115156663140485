<template>
  <section class="feature-demo-section">
    <v-container class="feature-demo py-12">
      <v-row justify="center" class="mb-8">
        <v-col cols="12" class="text-center">
          <h2 class="text-h1 text-sm-h3 text-xs-h3 font-weight-bold">{{ $t('featureDemo.heading') }}</h2>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" md="10">
          <v-carousel
            hide-delimiters
            :show-arrows="true"
            height="600"
            class="custom-carousel"
            @change="handleSlideChange"
          >
            <v-carousel-item
              v-for="(feature, i) in features"
              :key="i"
            >
              <v-row class="fill-height" align="center">
                <v-col cols="12" class="text-center mb-4">
                  <h3 class="text-h5 font-weight-bold mb-2">{{ feature.title }}</h3>
                  <p class="text-subtitle-1">{{ feature.description }}</p>
                </v-col>
                <v-col cols="12">
                  <v-img
                    :src="feature.image"
                    max-height="400"
                    contain
                    class="mx-auto"
                  ></v-img>
                </v-col>
              </v-row>
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { trackFeatureDemo } from '@/utils/analytics'

const { t, locale } = useI18n()
const currentIndex = ref(0)

const getLocalizedImage = (baseName) => {
  const suffix = locale.value === 'en' ? '_en' : ''
  return `/images/${baseName}${suffix}.png`
}

const features = computed(() => [
  {
    title: t('analysis.results.title'),
    description: t('analysis.features.analyse'),
    image: getLocalizedImage('karusell_analyse_ergebnisse_3')
  },
  {
    title: t('analysis.results.categories.raumaufteilung'),
    description: t('analysis.features.raumaufteilung'),
    image: getLocalizedImage('karusell_raumaufteilung')
  },
  {
    title: t('analysis.results.categories.raumfluss'),
    description: t('analysis.features.raumfluss'),
    image: getLocalizedImage('karusell_raumfluss')
  },
  {
    title: t('analysis.results.categories.flexibilitaet'),
    description: t('analysis.features.flexibilitaet'),
    image: getLocalizedImage('karusell_flexibilität')
  },
  {
    title: t('analysis.costEfficiency.sections.constructionCost.title'),
    description: t('analysis.features.baukosten'),
    image: getLocalizedImage('karusell_baukosten')
  },
  {
    title: t('analysis.roomEval.size'),
    description: t('analysis.features.raumgroesse'),
    image: getLocalizedImage('karusell_raumgroesse')
  },
  {
    title: t('analysis.improvements.title'),
    description: t('analysis.features.verbesserungen'),
    image: getLocalizedImage('karusell_verbesserungen3')
  },
  {
    title: t('analysis.improvements.title'),
    description: t('analysis.features.verbesserungen'),
    image: getLocalizedImage('karusell_verbesserungen2')
  },
  {
    title: t('analysis.improvements.title'),
    description: t('analysis.features.verbesserungen'),
    image: getLocalizedImage('karusell_verbesserungen1')
  }
])

// Preload images
features.value.forEach(feature => {
  const img = new Image()
  img.src = feature.image
})

const handleSlideChange = (index) => {
  const direction = index > currentIndex.value ? 'next' : 'previous'
  const feature = features.value[index]
  
  trackFeatureDemo(direction, feature.title)
  trackFeatureDemo('view', feature.title)
  
  currentIndex.value = index
}

onMounted(() => {
  if (features.value.length > 0) {
    trackFeatureDemo('view', features.value[0].title)
  }
})
</script>

<style scoped>
.custom-carousel :deep(.v-btn--icon) {
  background-color: transparent !important;
  border: none;
  height: 48px !important;
  width: 48px !important;
  margin: 0 1rem;
  box-shadow: none !important;
}

.custom-carousel :deep(.v-btn__content) {
  color: rgba(0, 0, 0, 0.5);
  font-size: 32px;
  font-weight: bold;
}

.custom-carousel :deep(.v-btn--icon:hover) .v-btn__content {
  color: rgba(0, 0, 0, 0.8);
}

.custom-carousel :deep(.v-window__prev) {
  left: -50px;
}

.custom-carousel :deep(.v-window__next) {
  right: -50px;
}

@media (max-width: 600px) {
  .feature-demo-section .feature-demo {
    padding-top: 1rem !important;
    padding-bottom: 2rem !important;
  }
  
  h2 {
    font-size: 1.75rem !important;
  }
}

@media (min-width: 601px) and (max-width: 960px) {
  h2 {
    font-size: 2.125rem !important;
  }
}
</style> 