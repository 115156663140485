<template>
  <v-app>
    <v-app-bar
      :elevation="0"
      class="px-0"
      height="80"
      fixed
    >
      <v-container class="px-6">
        <div class="d-flex align-center">
          <router-link to="/" class="text-decoration-none">
            <div class="d-flex align-center">
              <v-icon
                color="primary"
                size="32"
                class="mr-2"
              >
                mdi-floor-plan
              </v-icon>
              <span class="text-h6 font-weight-bold text-primary">{{ $t('nav.brand') }}</span>
            </div>
          </router-link>
          
          <v-spacer></v-spacer>
          
          <div class="d-none d-md-flex align-center gap-2">
            <v-btn
              v-for="(item, i) in menuItems"
              :key="i"
              variant="text"
              class="text-none font-weight-medium"
              @click="goToSection(item.section, item.to)"
            >
              {{ $t(item.title) }}
            </v-btn>
            
            <LanguageSwitcher class="mx-2" @language-changed="handleLanguageChange" />
            
            <v-divider vertical class="mx-2"></v-divider>
            
            <template v-if="!authStore.isAuthenticated">
              <v-btn
                variant="text"
                to="/sign-in"
                class="text-none"
                elevation="0"
              >
                <v-icon icon="mdi-login" class="mr-1" size="small"></v-icon>
                {{ $t('nav.auth.signIn') }}
              </v-btn>
              <v-btn
                variant="elevated"
                to="/sign-up"
                color="primary"
                class="text-none"
                elevation="0"
              >
                <v-icon icon="mdi-account-plus" class="mr-1" size="small"></v-icon>
                {{ $t('nav.auth.signUp') }}
              </v-btn>
            </template>
            
            <v-menu v-else location="bottom end" transition="scale-transition">
              <template v-slot:activator="{ props }">
                <v-btn
                  class="text-none"
                  variant="text"
                  v-bind="props"
                >
                  <v-icon icon="mdi-account-circle" class="mr-1"></v-icon>
                  {{ authStore.user?.email }}
                  <v-icon icon="mdi-chevron-down" size="small" class="ml-1"></v-icon>
                </v-btn>
              </template>

              <v-list min-width="200">
                <v-list-item>
                  <template v-slot:prepend>
                    <v-icon icon="mdi-email" class="mr-2"></v-icon>
                  </template>
                  <v-list-item-title class="text-body-2">
                    {{ authStore.user?.email }}
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  style="cursor: pointer"
                  @click="goToSection('pricing')"
                >
                  <template v-slot:prepend>
                    <v-icon icon="mdi-account-cash" class="mr-2"></v-icon>
                  </template>
                  <v-list-item-title class="text-body-2">
                    {{ authStore.credits }} Credits
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  to="/analysis"
                  rounded="lg"
                  @click="store.commit('resetAnalysisData')"
                >
                  <template v-slot:prepend>
                    <v-icon icon="mdi-floor-plan" class="mr-2"></v-icon>
                  </template>
                  <v-list-item-title class="text-body-2">
                    {{ $t('nav.menu.analysis') }}
                  </v-list-item-title>
                </v-list-item>

                <v-divider class="my-2"></v-divider>

                <v-list-item
                  @click="handleSignOut"
                  color="error"
                >
                  <template v-slot:prepend>
                    <v-icon icon="mdi-logout" class="mr-2"></v-icon>
                  </template>
                  <v-list-item-title class="text-body-2">
                    {{ $t('nav.auth.signOut') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-btn
              v-if="showGetStartedButton"
              color="primary"
              class="text-none rounded-lg ml-4"
              elevation="0"
              @click="scrollToTop"
            >
              {{ $t('nav.getStarted') }}
              <v-icon right class="ml-2">mdi-arrow-right</v-icon>
            </v-btn>
          </div>
          
          <v-app-bar-nav-icon
            class="d-flex d-md-none"
            @click="drawer = !drawer"
          ></v-app-bar-nav-icon>
        </div>
      </v-container>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      temporary
    >
      <v-list>
        <v-list-item
          v-for="(item, i) in menuItems"
          :key="i"
          @click="goToSection(item.section, item.to)"
          rounded="lg"
        >
          <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
        </v-list-item>

        <v-divider class="my-4"></v-divider>

        <template v-if="!authStore.isAuthenticated">
          <v-list-item
            to="/sign-in"
            rounded="lg"
          >
            <template v-slot:prepend>
              <v-icon icon="mdi-login"></v-icon>
            </template>
            <v-list-item-title>{{ $t('nav.auth.signIn') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            to="/sign-up"
            rounded="lg"
          >
            <template v-slot:prepend>
              <v-icon icon="mdi-account-plus"></v-icon>
            </template>
            <v-list-item-title>{{ $t('nav.auth.signUp') }}</v-list-item-title>
          </v-list-item>
        </template>
        
        <template v-else>
          <v-list-item>
            <template v-slot:prepend>
              <v-icon icon="mdi-account-circle" class="mr-2"></v-icon>
            </template>
            <v-list-item-title class="text-body-2">
              {{ authStore.user?.email }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            style="cursor: pointer"
            @click="goToSection('pricing')"
          >
            <template v-slot:prepend>
              <v-icon icon="mdi-account-cash" class="mr-2"></v-icon>
            </template>
            <v-list-item-title class="text-body-2">
              {{ authStore.credits }} Credits
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            to="/analysis"
            rounded="lg"
            @click="store.commit('resetAnalysisData')"
          >
            <template v-slot:prepend>
              <v-icon icon="mdi-floor-plan" class="mr-2"></v-icon>
            </template>
            <v-list-item-title class="text-body-2">
              {{ $t('nav.menu.analysis') }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            @click="handleSignOut"
            color="error"
            rounded="lg"
          >
            <template v-slot:prepend>
              <v-icon icon="mdi-logout" class="mr-2"></v-icon>
            </template>
            <v-list-item-title class="text-body-2">
              {{ $t('nav.auth.signOut') }}
            </v-list-item-title>
          </v-list-item>
        </template>

        <v-list-item>
          <LanguageSwitcher @language-changed="handleLanguageChange" />
        </v-list-item>

        <v-list-item v-if="showGetStartedButton" class="mt-4">
          <v-btn
            block
            color="primary"
            class="text-none"
            elevation="0"
            @click="scrollToTop"
          >
            {{ $t('nav.getStarted') }}
            <v-icon right class="ml-2">mdi-arrow-right</v-icon>
          </v-btn>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main class="pt-16">
      <router-view></router-view>
    </v-main>

    <v-footer class="bg-background">
      <v-container>
        <v-row align="center" class="py-4">
          <v-col cols="12" md="6" class="text-center text-md-left">
            <span class="text-medium-emphasis text-body-2">
              {{ $t('nav.footer.copyright', { year: new Date().getFullYear() }) }}
            </span>
          </v-col>
          <v-col cols="12" md="6" class="text-center text-md-right">
            <v-btn
              v-for="(link, i) in footerLinks"
              :key="i"
              variant="text"
              class="text-none text-medium-emphasis text-body-2 mx-2"
              :to="link.to"
            >
              {{ $t(link.title) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script setup>
import { ref, watch, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import LanguageSwitcher from '@/components/LanguageSwitcher.vue';
import { useAuthStore } from '@/stores/auth';
import { auth } from '@/firebase/config';

const router = useRouter();
const store = useStore();
const drawer = ref(false);
const authStore = useAuthStore();

const menuItems = [
  { title: 'nav.menu.features', section: 'how-it-works', icon: 'mdi-help-circle-outline' },
  { title: 'nav.menu.pricing', section: 'pricing', icon: 'mdi-currency-usd' },
  { title: 'nav.menu.about', section: 'faq', icon: 'mdi-frequently-asked-questions' },
  { title: 'nav.menu.articles', to: '/artikel', icon: 'mdi-text-box-multiple' }
];

const footerLinks = [
  { title: 'nav.footer.privacy', to: '/datenschutz' },
  { title: 'nav.footer.imprint', to: '/impressum' }
];

const showGetStartedButton = computed(() => {
  return router.currentRoute.value.path !== '/analysis';
});

const handleSignOut = async () => {
  await auth.signOut();
  store.commit('resetState');
  router.push('/');  
};

const handleSignInSuccess = () => {
  if (router.currentRoute.value.path === '/') {
    router.push('/analysis');
  }
};

watch(() => authStore.isAuthenticated, (newValue) => {
  if (newValue) {
    setTimeout(() => {
      handleSignInSuccess();
    }, 100);
  }
});

const goToSection = (section, to) => {
  if (to) {
    router.push(to);
    drawer.value = false;
    return;
  }

  if (router.currentRoute.value.path !== '/') {
    router.push('/')
    setTimeout(() => {
      const element = document.getElementById(section)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }, 100)
  } else {
    const element = document.getElementById(section)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }
  drawer.value = false
}

const scrollToTop = () => {
  if (router.currentRoute.value.path.includes('sign-')) {
    router.push('/');
  } else {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  drawer.value = false;
};

const handleLanguageChange = () => {
  // Force page reload
  window.location.reload();
};
</script>

<style>
:root {
  --v-background-base: var(--background);
  --v-primary-base: var(--primary);
  --v-primary-lighten1: var(--primary-light);
  --v-primary-darken1: var(--primary-dark);
}

.v-application {
  background-color: var(--background) !important;
}

.text-primary {
  color: var(--primary) !important;
}

.text-h6 {
  line-height: 1.2;
  font-weight: 700 !important;
}

.v-btn {
  font-weight: 600;
}

.v-app-bar {
  background-color: var(--background) !important;
}

.klaro {
  z-index: 2000 !important;
}

.klaro .cookie-modal {
  z-index: 2001 !important;
}

.klaro .cookie-notice {
  z-index: 2001 !important;
  position: fixed !important;
}
</style>
