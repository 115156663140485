<template>
  <v-col cols="12">
    <v-card class="cost-analysis pa-4">
      <v-card-title>{{ $t('analysis.costEfficiency.title') }}</v-card-title>

      <template v-if="loading">
        <div class="d-flex flex-column align-center ma-4">
          <v-progress-circular
            indeterminate
            color="primary"
            class="mb-4"
          ></v-progress-circular>
          <span class="text-medium-emphasis">{{ $t('analysis.costEfficiency.loading') }}</span>
        </div>        
      </template>

      <template v-else-if="error">
        <div class="text-center error--text">
          {{ error }}
          <br />
          <v-btn
            color="primary"
            variant="text"
            class="mt-2"
            :loading="loading"
            @click="fetchCostAnalysis"
          >
            <v-icon icon="mdi-refresh" class="mr-2" />
            {{ $t('common.retry') }}
          </v-btn>
        </div>
      </template>
      
      <template v-else-if="analysis">
        <!-- Summary -->
        <v-card-text class="analysis-summary mt-4" :class="{ 'premium-item': !hasPremiumAccess }">
          <div class="text-subtitle-1 font-weight-bold mb-2">
            {{ $t('analysis.costEfficiency.sections.summary.title') }}
          </div>
          <PremiumContent 
            :is-premium="!hasPremiumAccess"
            :preview-content="analysis.summary.substring(0, 20)"
            @upgrade="handlePremiumContent('summary')"
          >
            <span class="analysis-description text-wrap">
              {{ analysis.summary }}
            </span>
          </PremiumContent>
        </v-card-text>

        <!-- Space Efficiency -->
        <v-card-text class="analysis-section">
          <div class="d-flex justify-space-between align-center mb-2">
            <h3 class="text-subtitle-1 font-weight-bold">
              {{ $t('analysis.costEfficiency.sections.spaceEfficiency.title') }}
            </h3>
            <v-rating
              :model-value="analysis.spaceEfficiency.score"
              readonly
              density="compact"
              color="primary"
              length="5"
            ></v-rating>
          </div>
            <span class="analysis-description text-wrap"> 
              {{ analysis.spaceEfficiency.description }}
            </span>
        </v-card-text>

        <!-- Construction Cost -->
        <v-card-text class="analysis-section" :class="{ 'premium-item': !hasPremiumAccess }">
          <div class="d-flex justify-space-between align-center mb-2">
            <h3 class="text-subtitle-1 font-weight-bold">
              {{ $t('analysis.costEfficiency.sections.constructionCost.title') }}
            </h3>
            <v-rating
              :model-value="analysis.constructionCost.score"
              readonly
              density="compact"
              color="primary"
              length="5"
            ></v-rating>
          </div>
          <PremiumContent 
            :is-premium="!hasPremiumAccess"
            :preview-content="analysis.constructionCost.description.substring(0, 20)"
            @upgrade="handlePremiumContent('construction_cost')"
          >
            <span class="analysis-description text-wrap">
                {{ analysis.constructionCost.description }}
            </span>
          </PremiumContent>
        </v-card-text>

        <!-- Energy Efficiency -->
        <v-card-text class="analysis-section" :class="{ 'premium-item': !hasPremiumAccess }">
          <div class="d-flex justify-space-between align-center mb-2">
            <h3 class="text-subtitle-1 font-weight-bold">
              {{ $t('analysis.costEfficiency.sections.energyEfficiency.title') }}
            </h3>
            <v-rating
              :model-value="analysis.energyEfficiency.score"
              readonly
              density="compact" 
              color="primary" 
              length="5"
            ></v-rating>
          </div>
          <PremiumContent 
            :is-premium="!hasPremiumAccess"
            :preview-content="analysis.energyEfficiency.description.substring(0, 20)"
            @upgrade="handlePremiumContent('energy_efficiency')"
          >
            <span class="analysis-description text-wrap">{{ analysis.energyEfficiency.description }}</span>            
          </PremiumContent>
        </v-card-text>
      </template>
    </v-card>
  </v-col>
</template>

<script setup>
import { ref, onMounted, computed, watch } from 'vue'
import axios from 'axios'
import { useI18n } from 'vue-i18n'
import { useAuthStore } from '@/stores/auth'
import PremiumContent from '@/components/common/PremiumContent.vue'
import { trackCostEfficiencyPremiumClick } from '@/utils/analytics'
import { getIdToken } from 'firebase/auth'
import { useStore } from 'vuex'

const { t, locale } = useI18n()
const authStore = useAuthStore()
const store = useStore()

// Compute whether user has access to premium content
const hasPremiumAccess = computed(() => store.state.userHadPremiumWhenUploaded)

const props = defineProps({
  imageUrl: {
    type: String,
    required: true
  }
})

const loading = ref(false)
const error = ref(null)
const analysis = computed(() => store.state.analysisCost || {
  spaceEfficiency: { score: 0, description: '' },
  constructionCost: { score: 0, description: '' },
  energyEfficiency: { score: 0, description: '' },
  summary: ''
})

const fetchCostAnalysis = async () => {
  if (loading.value) {
    return;
  }

  // If we already have analysis data in store and the imageUrl hasn't changed, use that
  if (store.state.analysisCost && store.state.lastAnalyzedImage === props.imageUrl) {
    loading.value = false;
    return;
  }

  // Reset state when starting new analysis
  store.commit('setAnalysisCost', null)
  loading.value = true
  error.value = null
  
  try {
    let headers = {}
    if (authStore.isAuthenticated) {
      const token = await getIdToken(authStore.user)
      headers.Authorization = `Bearer ${token}`
    }

    const response = await axios.post('/api/analyze-cost', {
      imageUrl: props.imageUrl,
      language: locale.value,
      model: store.state.modelQuality
    }, { headers })
    
    if (response.data?.data) {
      // Store the cost analysis data in Vuex
      store.commit('setAnalysisCost', response.data.data)
      // Store the last analyzed image URL
      store.commit('setLastAnalyzedImage', props.imageUrl)
      // Refresh credits after successful analysis
      await authStore.fetchCredits()
    } else {
      throw new Error('analysis.costEfficiency.error.invalidResponse')
    }
  } catch (err) {
    error.value = err.message === 'analysis.costEfficiency.error.invalidResponse' 
      ? t('analysis.costEfficiency.error.invalidResponse')
      : t('analysis.costEfficiency.error.default')
    console.error('CostEfficiencyAnalysis: Analysis failed:', err)
  } finally {
    loading.value = false
  }
}

// Modify the watcher to use immediate: false
watch(() => props.imageUrl, (newUrl, oldUrl) => {
  if (newUrl && newUrl !== oldUrl) {
    fetchCostAnalysis()
  }
}, { immediate: false })

// Add mounted hook to handle initial load
onMounted(() => {
  if (props.imageUrl && !store.state.analysisCost) {
    fetchCostAnalysis()
  }
})

// Add navigation helper
const navigateToPricing = () => {
  window.scrollTo({
    top: document.documentElement.scrollHeight,
    behavior: 'smooth'
  });
}

// Add premium content handler
const handlePremiumContent = (section) => {
  trackCostEfficiencyPremiumClick(section)
  
  if (!hasPremiumAccess.value) {
    navigateToPricing()
  }
}
</script>

<style scoped>
.cost-analysis {
  border-radius: 8px;
}

.analysis-section {
  border-bottom: 1px solid var(--v-border-color);
  padding-top: 16px;
  padding-bottom: 16px;
}

.analysis-section:last-child {
  border-bottom: none;
}

.analysis-summary {
  background-color: var(--v-surface-variant);
  border-radius: 4px;
  position: relative;
}

.analysis-description {
  white-space: normal !important;
  margin-top: 4px;
  color: var(--text-primary);
  overflow: visible;
  text-overflow: unset;
  word-wrap: break-word;
}

:deep(.text-medium-emphasis) {
  opacity: 0.7;
  font-size: 0.9em;
}

.premium-item {
  position: relative;
}

.premium-item:hover .premium-message {
  display: flex;
}
</style>

