<template>
  <div class="hero">
    <v-container class="h-100">
      <div class="hero-content text-center">
        <h1 class="text-h1 text-sm-h3 text-xs-h3 font-weight-bold mb-11">
          {{ $t('hero.title.prefix') }}
          <span class="highlight">{{ $t('hero.title.highlight') }}</span>
          {{ $t('hero.title.suffix') }}
        </h1>
        <div class="features d-flex flex-column gap-4 mb-6 mx-auto">
          <div class="feature-item d-flex align-center justify-left">
            <v-icon color="primary" class="mr-4">mdi-check-circle</v-icon>
            <span>{{ $t('hero.features.forBuilders') }}</span>
          </div>
          <div class="feature-item d-flex align-center justify-left">
            <v-icon color="primary" class="mr-4">mdi-check-circle</v-icon>
            <span>{{ $t('hero.features.detailedEvaluation') }}</span>
          </div>
          <div class="feature-item d-flex align-center justify-left">
            <v-icon color="primary" class="mr-4">mdi-check-circle</v-icon>
            <span>{{ $t('hero.features.optimizationTips') }}</span>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script setup>
</script>

<style scoped>
.hero {
  min-height: 21vh;
  background: var(--background);
  padding: 4rem 0 0 0;
}

.hero-content {  
  margin: 0 auto;
  max-width: 950px;
}

.text-h4 {
  color: var(--text-primary);
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 6rem;
}

.feature-item {
  color: var(--text-secondary);
  font-size: 1rem;
  font-weight: 500;
}

.feature-item .v-icon {
  color: var(--primary) !important;
}

.highlight {
  color: var(--v-primary-base);
  font-weight: 700;
}

.features {
  max-width: 400px;
}

@media (max-width: 600px) {
  .hero {
    padding: 1rem 0 0 0;
  }
  
  .hero h1 {
    font-size: 1.75rem !important;
  }
}

@media (min-width: 601px) and (max-width: 960px) {
  .hero h1 {
    font-size: 2.125rem !important;
  }
}
</style>
