import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Analysis from '../views/Analysis.vue'
import Impressum from '../views/Impressum.vue'
import Datenschutz from '../views/Datenschutz.vue'
import Articles from '../views/Articles.vue'
import OptimaleRaumaufteilung from '../views/articles/01_OptimaleRaumaufteilung.vue'
import NachhaltigerGrundriss from '../views/articles/02_NachhaltigerGrundriss.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/analysis',
    name: 'Analysis',
    component: Analysis
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: Impressum
  },
  {
    path: '/datenschutz',
    name: 'Datenschutz',
    component: Datenschutz
  },
  {
    path: '/artikel',
    name: 'Articles',
    component: Articles
  },
  {
    path: '/artikel/optimal-floorplan',
    name: 'OptimaleRaumaufteilung',
    component: OptimaleRaumaufteilung
  },
  {
    path: '/artikel/nachhaltiger-grundriss',
    name: 'NachhaltigerGrundriss',
    component: NachhaltigerGrundriss
  },
  {
    path: '/sign-in',
    name: 'SignIn',
    component: () => import('@/components/auth/SignIn.vue')
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: () => import('@/components/auth/SignUp.vue')
  },
  {
    path: '/thank-you',
    name: 'ThankYou',
    component: () => import('@/views/ThankYou.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// Navigation guard
router.beforeEach((to, from, next) => {
  next();
});

// Google Analytics route tracking
router.afterEach((to) => {
  // Only track if gtag is available and GA ID is configured
  if (window.gtag && process.env.VUE_APP_GA_ID) {
    window.gtag('config', process.env.VUE_APP_GA_ID, {
      'page_path': to.fullPath
    });
  }
})

export default router
