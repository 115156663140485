import 'klaro/dist/klaro.css';
import { klaroConfig } from '../config/klaro-config';

// Initialize Google Analytics with consent check
export function initializeAnalytics() {
    return new Promise((resolve, reject) => {
        try {
            // Check if GA script is already loaded
            if (document.querySelector('script[src*="googletagmanager.com/gtag/js"]')) {
                resolve();
                return;
            }

            // Initialize dataLayer if not already done in index.html
            if (!window.dataLayer) {
                window.dataLayer = window.dataLayer || [];
                window.gtag = function() {
                    window.dataLayer.push(arguments);
                };
            }

            // Load Google Analytics script if not already loaded
            const script = document.createElement('script');
            script.async = true;
            script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.VUE_APP_GA_ID}`;
            
            script.onload = () => {
                // Only initialize if not already done in index.html
                if (!document.querySelector('script[data-ga-initialized]')) {
                    window.gtag('js', new Date());
                    window.gtag('config', process.env.VUE_APP_GA_ID, {
                        'anonymize_ip': true,
                        'cookie_flags': 'SameSite=None;Secure'
                    });
                }
                resolve();
            };

            script.onerror = () => {
                reject(new Error('Failed to load Google Analytics'));
            };

            document.head.appendChild(script);
        } catch (error) {
            reject(error);
        }
    });
}

// Initialize Klaro
export function initializeKlaro() {
    return new Promise((resolve, reject) => {
        try {
            // Check if Klaro is already loaded
            if (window.klaro) {
                resolve();
                return;
            }

            // Load Klaro
            const script = document.createElement('script');
            script.defer = true;
            script.src = 'https://cdn.kiprotect.com/klaro/v0.7/klaro.js';
            
            script.onload = () => {
                // Add Klaro config to window
                window.klaroConfig = {
                    ...klaroConfig,
                    noAutoLoad: false, // Allow Klaro to handle showing automatically
                };

                // Initialize Klaro
                window.klaro.setup(window.klaroConfig);
                
                resolve();
            };

            script.onerror = () => {
                reject(new Error('Failed to load Klaro'));
            };

            document.head.appendChild(script);
        } catch (error) {
            reject(error);
        }
    });
}
