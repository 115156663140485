<template>
  <v-row id="how-it-works" class="features-section py-12">
    <div class="background-overlay"></div>
    <v-container class="position-relative">
      <v-row>
        <v-col cols="12" class="text-center mb-8">
          <h2 class="text-h1 text-sm-h3 text-xs-h3 font-weight-bold">{{ $t('howItWorks.title') }}</h2>
          <p class="text-subtitle-1 mt-2">{{ $t('howItWorks.subtitle') }}</p>
        </v-col>
      </v-row>

      <v-row class="mt-4">
        <v-col
          v-for="(feature, key) in features"
          :key="key"
          cols="12"
          sm="6"
          md="4"
          class="d-flex"
        >
          <v-card class="feature-card" elevation="0">
            <v-card-item>
              <v-icon
                size="48"
                color="primary"
                class="mb-4"
              >
                {{ feature.icon }}
              </v-icon>
              <h3 class="text-h6 font-weight-bold mb-2">{{ $t(`howItWorks.features.${key}.title`) }}</h3>
              <p class="text-body-1">{{ $t(`howItWorks.features.${key}.description`) }}</p>
            </v-card-item>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-row>
</template>

<script setup>
const features = {
  aiAnalysis: {
    icon: 'mdi-lightning-bolt'
  },
  roomEvaluation: {
    icon: 'mdi-floor-plan'
  },
  costEfficiency: {
    icon: 'mdi-chart-bar'
  },
  accessibility: {
    icon: 'mdi-account-check'
  },
  recommendations: {
    icon: 'mdi-lightbulb'
  },
  inspiration: {
    icon: 'mdi-database-search'
  }
};
</script>

<style scoped>
.features-section {
  position: relative;
  background: var(--background);
  color: var(--text-primary);
  padding: 5rem 0;
}

.text-h4 {
  color: var(--text-primary);
  font-weight: 700;
  margin-bottom: 1rem;
}

.text-subtitle-1 {
  color: var(--text-secondary);
  font-size: 1.125rem;
  line-height: 1.6;
}

.feature-card {
  background: white;
  border: 1px solid var(--border-color);
  border-radius: 16px !important;
  height: 100%;
  transition: all 0.3s ease;
  padding: 2rem;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-md);
}

:deep(.v-card-item) {
  padding: 0;
}

.v-icon {
  color: var(--primary) !important;
  opacity: 0.9;
  margin-bottom: 1.5rem;
}

.text-h6 {
  color: var(--text-primary);
  font-weight: 700;
  font-size: 1.25rem !important;
  line-height: 1.4;
  margin-bottom: 1rem;
}

.text-body-1 {
  color: var(--text-secondary);
  line-height: 1.6;
  font-size: 1rem;
}

@media (max-width: 600px) {
  h2 {
    font-size: 1.75rem !important;
  }
}

@media (min-width: 601px) and (max-width: 960px) {
  h2 {
    font-size: 2.125rem !important;
  }
}

.how-it-works-section {
  padding: 4rem 0;
  background: var(--background);
}

@media (max-width: 600px) {
  .how-it-works-section {
    padding: 2rem 0;
  }
}
</style>
